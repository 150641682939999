import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import hardDriveSpace from "@/services/hard-drive-space";

import Modal from "@toolbox/modals/Modal";
import T, {intl2Num, intl2Str} from "@translate/T";
import {NO_SOP_VALUE} from "./models";

interface IModalWarningProps {
    afterClose?(): void;
}

interface IModalWarningState {
    value: number;
    open: boolean;
}

class ModalWarning extends React.PureComponent<
    IModalWarningProps,
    IModalWarningState
> {
    public readonly state: IModalWarningState = {
        value: hardDriveSpace.value,
        open: false,
    };

    private readonly ref = React.createRef<Modal>();
    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = hardDriveSpace.subscribe({
            hardDriveSpaceChanged: (value) => this.setState({value}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public open() {
        this.setState({open: true});
    }

    @boundMethod
    public afterClose() {
        this.setState({open: false}, this.props.afterClose);
    }

    public render() {
        if (!this.state.open) {
            return null;
        }

        return (
            <Modal
                contentClassName="alert alert-warning p-0 m-0"
                size="sm"
                afterClose={this.afterClose}
            >
                <div className="modal-body">
                    <h5>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Disk space</T>
                    </h5>
                    <hr />
                    <div className="text-center">
                        <IntlContext.Consumer children={this.renderMessage} />
                    </div>
                </div>
            </Modal>
        );
    }

    @boundMethod
    private renderMessage(intl: IntlShape) {
        const {value} = this.state;
        if (value > NO_SOP_VALUE) {
            return intl2Str(
                intl,
                "Server has {value} GB of free space left, should be at least 10 GB, please contact your administrator.",
                {
                    value: intl2Num(intl, value, 1),
                },
            );
        }

        return intl2Str(
            intl,
            "Server has {value} GB of free space left, should be at least 10 GB, measurements cannot be performed before more than 3 GB are available, please contact your administrator.",
            {
                value: intl2Num(intl, value, 1),
            },
        );
    }
}

export default ModalWarning;
