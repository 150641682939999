import {EModules} from "@/models";
import {EDeviceClasses} from "../models";

import {hasLightFactor} from "./device-check";

export function hasBarGraph(engine: EModules) {
    return hasManual(engine) || engine === EModules.Astm;
}

export function hasDoublePlot(engine: EModules) {
    return engine === EModules.FirstDerivative || isPsa(engine);
}

export function hasFit(engine: EModules) {
    return isPsa(engine) || isSize(engine);
}

export function hasLengthUnit(engine: EModules) {
    return isPsa(engine) || engine === EModules.FrontTracking;
}

export function hasManual(engine: EModules) {
    return (
        isPsa(engine) ||
        isBasic(engine) ||
        engine === EModules.Filtration ||
        engine === EModules.SedimentationHeight ||
        engine === EModules.FirstDerivative ||
        engine === EModules.MwlRatio ||
        engine === EModules.SpocCount ||
        isSize(engine)
    );
}

export function hasMaterial(engine: EModules) {
    return (
        isWeighted(engine) ||
        engine === EModules.SedimentationHeight ||
        engine === EModules.Filtration
    );
}

export function hasMaterialBoth(engine: EModules) {
    return hasMaterial(engine) && !hasMaterialNoDispersed(engine);
}

export function hasMaterialNoDispersed(engine: EModules) {
    return engine === EModules.Filtration;
}

export function hasNodes(engine: EModules) {
    return isConstantPos(engine) || isPsaMwl(engine);
}

export function hasRegression(engine: EModules) {
    return engine === EModules.Integration || engine === EModules.FrontTracking;
}

export function hasResultChart(engine: EModules) {
    return (
        engine !== EModules.LumView &&
        engine !== EModules.Astm &&
        engine !== EModules.SedimentationHeight
    );
}

export function hasSauter(engine: EModules) {
    return engine.includes(EModules.Volume) || engine.includes(EModules.Number);
}

export function hasSeparation(engine: EModules) {
    return (
        engine.includes(EModules.FrontTracking) ||
        engine.includes(EModules.SedimentationHeight)
    );
}

export function hasSizeSlider(engine: EModules) {
    return engine.includes(EModules.SpocCount) || isSize(engine);
}

export function hasStartTime(engine: EModules) {
    return engine !== EModules.Instability;
}

export function hasStaticIndexes(engine: EModules) {
    return (
        isBasic(engine) ||
        engine === EModules.FirstDerivative ||
        engine === EModules.MwlRatio
    );
}

export function hasStatsTable(engine: EModules) {
    return engine !== EModules.LumView && engine !== EModules.Astm;
}

export function hasThreshold(engine: EModules) {
    return (
        engine === EModules.Instability ||
        engine === EModules.FrontTracking ||
        engine === EModules.SpocCount ||
        isSize(engine)
    );
}

export function hasTime(engine: EModules) {
    return engine === EModules.LumView || isRange(engine) || isPsa(engine);
}

export function hasTransformation(engine: EModules) {
    return engine === EModules.LumView || isBasic(engine);
}

export function isBasic(engine: EModules) {
    return (
        engine === EModules.FrontTracking ||
        engine === EModules.Instability ||
        engine === EModules.Integration
    );
}

export function isCent(engine: EModules) {
    return engine !== EModules.Frac && !isSpoc(engine);
}

export function isConstantPos(engine: EModules) {
    return engine.startsWith(EModules.PsaPos);
}

export function isConstantTime(engine: EModules) {
    return engine.startsWith(EModules.PsaTime);
}

export function isExtinction(engine: EModules) {
    return engine.includes(EModules.Intensity);
}

export function isMwl(engine: EModules) {
    return engine.includes(EModules.Mwl);
}

export function isMwlOrAstmEngine(engine: EModules) {
    return isMwl(engine) || engine === EModules.Astm;
}

export function isNumber(engine: EModules) {
    return engine.includes(EModules.Number);
}

export function isPsa(engine: EModules) {
    return engine.startsWith(EModules.Psa);
}

export function isPsaMwl(engine: EModules) {
    return engine.includes(EModules.PsaMwl);
}

export function isPsaNoMwl(engine: EModules) {
    return isPsa(engine) && !isPsaMwl(engine);
}

export function isPsaNumber(engine: EModules) {
    return isPsa(engine) && isNumber(engine);
}

export function isPsaNumberNoMwl(engine: EModules) {
    return isPsaNoMwl(engine) && isNumber(engine);
}

export function isPsaVolume(engine: EModules) {
    return isPsa(engine) && isVolume(engine);
}

export function isPsaVolumeNoMwl(engine: EModules) {
    return isPsaNoMwl(engine) && isVolume(engine);
}

export function isRange(engine: EModules) {
    return engine.startsWith(EModules.Range);
}

export function isSize(engine: EModules) {
    return engine.includes(EModules.Size);
}

export function isSpoc(engine: EModules) {
    return engine.startsWith(EModules.Spoc);
}

export function isVelocity(engine: EModules) {
    return engine.includes(EModules.Velocity);
}

export function isVolume(engine: EModules) {
    return engine.includes(EModules.Volume);
}

export function isWeighted(engine: EModules) {
    return engine.includes(EModules.Weighted);
}

export function isXRayPsa(engine: EModules, device: EDeviceClasses) {
    return device === EDeviceClasses.XRay && isPsa(engine);
}

export function needAttenuation(engine: EModules, device: EDeviceClasses) {
    return hasTransformation(engine) && device === EDeviceClasses.XRay;
}

export function needDensity(
    engine: EModules,
    device: EDeviceClasses,
    isMass: boolean,
) {
    return (
        hasMaterial(engine) ||
        (isMass && hasTransformation(engine) && device === EDeviceClasses.XRay)
    );
}

export function needRefractiveIndex(engine: EModules, device: EDeviceClasses) {
    if (isXRayPsa(engine, device)) {
        return false;
    }

    // mwl does not need refactive index
    return (
        isPsaNumberNoMwl(engine) || isPsaVolumeNoMwl(engine) || isSize(engine)
    );
}

export function needViscosity(engine: EModules, type: "fluid" | "particle") {
    if (type === "particle") {
        return false;
    }

    return isWeighted(engine) || engine === EModules.Filtration;
}

export function noSSCCelltype(engine: EModules) {
    return (
        engine === EModules.Filtration ||
        engine === EModules.SedimentationHeight
    );
}

export function showRefProfile(
    engine: EModules,
    device: EDeviceClasses | EDeviceClasses[],
) {
    const notVisible = Array.isArray(device)
        ? device.some(hasLightFactor)
        : hasLightFactor(device);

    return (!notVisible && isWeighted(engine)) || isExtinction(engine);
}

export function startsWithMicrometer(engine: EModules) {
    return (
        isVelocity(engine) ||
        engine === EModules.Filtration ||
        engine === EModules.FrontTracking
    );
}

export function startsWithMillimeter(engine: EModules) {
    return engine === EModules.SedimentationHeight;
}
