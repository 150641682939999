import {differenceInCalendarDays, parseISO} from "date-fns";

import {ILicenseResponse} from "@/components/license/models";
import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import {ILicenses} from "@/services/models";
import {ILicensedDevice} from "@toolbox/models";

interface IEventHandler {
    licenseChanged?(licenseStatus: ILicenseStatus): void;
}

export interface ILicenseStatus extends ILicenseResponse {
    daysLeft: number;
    isUnlimited: boolean;
}

class LicenseService {
    private readonly events = new EventHandlers<IEventHandler>();

    private _status: ILicenseStatus = this.getDefault();

    // gives back a boolean, if license has any device of that deviceClass
    public getDeviceLicenses(overwriteDevices?: ILicensedDevice[]): ILicenses {
        const result: ILicenses = {
            cent: false,
            frac: false,
            fuge: false,
            reader: false,
            sizer: false,
            spoc: false,
            xray: false,
        };

        const devices = overwriteDevices ?? this._status.devices;
        for (const {deviceClass} of devices) {
            switch (deviceClass) {
                case "LUMiFrac":
                    result.frac = true;
                    break;

                case "LUMiFuge":
                    result.cent = true;
                    result.fuge = true;
                    break;

                case "LUMiReader":
                    result.cent = true;
                    result.reader = true;
                    break;

                case "LUMiSizer":
                    result.cent = true;
                    result.sizer = true;
                    break;

                case "LUMiSpoc":
                    result.spoc = true;
                    break;

                case "LUMiReader X-Ray":
                    result.cent = true;
                    result.xray = true;
                    break;
            }
        }

        return result;
    }

    public get status() {
        return this._status;
    }

    public load(value: ILicenseResponse) {
        const expiryDate = parseISO(value.expiry);
        const daysLeft = differenceInCalendarDays(expiryDate, new Date());
        const isUnlimited = daysLeft > 365 * 10;

        this._status = {...value, daysLeft, isUnlimited};
        this.events.publish((x) => x.licenseChanged?.(this._status));
    }

    // get license from server
    public async retrieveLicense() {
        try {
            const response = await http
                .get("/api/license")
                .json<ILicenseResponse>();
            if (!response.installationId) {
                // server is initializing database, try again later
                return false;
            }

            this.load(response);

            return true;
        } catch {
            return false;
        }
    }

    public silentReset() {
        this._status = this.getDefault();
    }

    public subscribe(handler: IEventHandler) {
        return this.events.register(handler);
    }

    private getDefault(): ILicenseStatus {
        return {
            daysLeft: 0,
            expiry: "",

            inactiveKeys: [],
            installationId: "",
            licensee: "",
            sessions: 0,
            users: 0,

            isTrial: true,
            isUnlimited: false,
            isValidSystemTime: true,
            isCorrupted: false,

            devices: [],
            modules: [],
            sops: [],
        };
    }
}

const license = new LicenseService();
export default license;
