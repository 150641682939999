import html2canvas from "html2canvas";
import {debounce} from "lodash";

import {IDLE_DELAY} from "@toolbox/models";
import loading from "./loading";
import {ELoadingStatus} from "./models";
import warning from "./warning";

export async function download(
    _name: string,
    content: Blob | string,
    mimeType?: string,
) {
    const name = _name.replace(/[\\/:*?"<>|]/g, "_"); // replace all invalid chars

    if (typeof content === "string") {
        content = "\ufeff" + content;
    }

    if (!(content instanceof Blob)) {
        content = new Blob([content], {type: mimeType});
    }

    // npm i @types/wicg-file-system-access
    // if (2 + 3 === 4) {
    //     try {
    //         const splitted = name.split(".");
    //         const extension = ("." + splitted[splitted.length - 1]);
    //         const dialog = await window.showSaveFilePicker({
    //             suggestedName: name,
    //             types: [{accept: {"*/*": [extension]}}],
    //         });

    //         const file = await dialog.createWritable();
    //         await file.write(content);
    //         await file.close();
    //     } catch (error) {
    //         console.log(error);
    //         // dOMException: The user aborted a request.
    //     }

    //     // once user wants file-picker, do not move futher
    //     return;
    // }

    // create download-button
    const a = document.createElement("a");
    const url = URL.createObjectURL?.(content);
    a.href = url;
    a.download = name;
    a.style.display = "none";
    document.body.appendChild(a);

    const _download = debounce(() => {
        a.click();
        document.body.removeChild(a);

        const remove = debounce(() => URL.revokeObjectURL?.(url), IDLE_DELAY);
        remove();
    }, IDLE_DELAY);
    _download();
}

// easily done via .parentElement, just send most top parent and all below will be in it
export async function html2Canvas(
    html: HTMLElement,
): Promise<HTMLCanvasElement> {
    const canvas = await html2canvas(html, {logging: false});

    return canvas;
}

// easily done via .parentElement, just send most top parent and all below will be in it
export async function html2Blob(html: HTMLElement): Promise<Blob> {
    const canvas = await html2Canvas(html);
    const blob = await canvas2Blob(canvas);

    return blob;
}

export function canvas2Blob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                resolve(blob);
                return;
            }

            reject();
        });
    });
}

// svg gets first printed
export async function svgAndCanvas2Canvas(
    svg: SvgCloner,
    canvas: () => HTMLCanvasElement,
): Promise<HTMLCanvasElement> {
    // firefox option
    // canvas();
    // return html2Canvas(svg.parentElement!);

    // alternativ way to do this
    // const blob = await svgAndCanvas2Blob(svg, canvas);
    // return blob2Canvas(blob);

    const {canvas: target, context} = await svg.toCanvas();
    blend(target, context, canvas);

    return target;
}

// canvas gets first printed
export async function svgAndCanvas2Canvas_CanvasFirst(
    svg: SvgCloner,
    canvas: () => HTMLCanvasElement,
) {
    const drawCanvas = (
        _target: HTMLCanvasElement,
        _context: CanvasRenderingContext2D,
    ) => blend(_target, _context, canvas);

    const {canvas: target} = await svg.toCanvas(drawCanvas);

    return target;
}

export function blob2DataURL(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = () => reject(reader.error);
        reader.onabort = () => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
    });
}

export async function blob2Canvas(blob: Blob) {
    const bitmap = await createImageBitmap(blob);

    return draw2Canvas(bitmap, bitmap.width, bitmap.height).canvas;
}

export function openBlob2NewTab(blob: Blob) {
    const blobUrl = URL.createObjectURL(blob);
    openURL2NewTab(blobUrl);
}

export function openURL2NewTab(url: string) {
    const newWin = window.open(url, "_blank");
    if (!newWin || newWin.closed) {
        // pop-up blocked
        return false;
    }

    newWin.focus();
    return true;
}

// you might not want to wait for it, since on button press it looks laggy
export async function blob2ClipBoard(
    blob: Blob | string,
    animation: boolean = false,
) {
    try {
        if (window.ClipboardItem === undefined) {
            warning.copy2ClipboardTrigger();
        } else if (typeof blob === "string") {
            await window.navigator.clipboard.writeText(blob);
        } else {
            const item = new window.ClipboardItem({[blob.type]: blob});
            await window.navigator.clipboard.write([item]);
        }

        if (animation) {
            loading.status = ELoadingStatus.Success;
        }
    } catch {
        if (animation) {
            loading.status = ELoadingStatus.Failed;
        }
    }
}

function blend(
    target: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    source: () => HTMLCanvasElement,
) {
    context.globalCompositeOperation = "multiply";
    context.drawImage(source(), 0, 0, target.width, target.height);
}

function draw2Canvas(
    img: CanvasImageSource,
    width: number,
    height: number,
    drawCanvas?: (
        target: HTMLCanvasElement,
        context: CanvasRenderingContext2D,
    ) => void,
) {
    const canvas = document.createElement("canvas");

    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext("2d")!;
    context.fillStyle = "white";
    context.fillRect(0, 0, width, height);

    drawCanvas?.(canvas, context);
    context.drawImage(img, 0, 0, width, height);

    return {canvas, context};
}

class SvgCloner {
    private readonly bound: DOMRect;
    private readonly clone: SVGSVGElement;

    public constructor(source: SVGSVGElement) {
        this.bound = source.getBoundingClientRect();
        this.clone = source.cloneNode(true) as SVGSVGElement;

        this.fixSize();
        this.copyFont(source);
    }

    public get outerHTML() {
        return this.clone.outerHTML;
    }

    public toUri() {
        return "data:image/svg+xml;base64," + btoa(this.outerHTML);
    }

    // similar to toUri()
    public toBlobUrl() {
        const blob = new Blob([this.outerHTML], {
            type: "image/svg+xml;charset=utf-8",
        });

        return URL.createObjectURL(blob);
    }

    public async toCanvas(
        drawCanvas?: (
            target: HTMLCanvasElement,
            context: CanvasRenderingContext2D,
        ) => void,
    ) {
        return new Promise<{
            canvas: HTMLCanvasElement;
            context: CanvasRenderingContext2D;
        }>((resolve) => {
            const img = new Image();
            img.onload = () => {
                const bound = this.bound;

                const {canvas, context} = draw2Canvas(
                    img,
                    bound.width,
                    bound.height,
                    drawCanvas,
                );

                resolve({canvas, context});
            };

            // setting the .src will trigger the .onload
            img.src = this.toBlobUrl();
        });
    }

    public async toBlob() {
        const {canvas} = await this.toCanvas();
        return canvas2Blob(canvas);
    }

    public setHeight(height: string) {
        this.clone.style.height = height;
        return this;
    }

    public setWidth(width: string) {
        this.clone.style.width = width;
        return this;
    }

    private copyFont(svg: Element) {
        const target = this.clone.style;
        const source = getComputedStyle(svg);

        target.fontSize = source.fontSize;
        target.fontFamily = source.fontFamily;
        target.fontWeight = source.fontWeight;
    }

    private fixSize() {
        const style = this.clone.style;
        const bound = this.bound;
        style.width = bound.width + "px";
        style.height = bound.height + "px";
    }
}

export default SvgCloner;
