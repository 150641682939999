import {faMinusSquare, faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {AnimatePresence, motion} from "framer-motion";
import React from "react";

import {ECheckStates} from "@toolbox/button-like/models";
import {animation, onClosed, onOpen} from "@toolbox/design/models";
import {getDocIdInfo} from "./doc-type";
import {
    ESortColumns,
    IDocIdInfo,
    IItemSelectionState,
    ISearchDocumentModel,
    ISearchItem,
    ISelectedItem,
} from "./models";

import SortHeader from "@toolbox/building-blocks/SortHeader";
import T from "@translate/T";
import SearchItem from "./SearchItem";
import TriStateSplitButton from "./TriStateSplitButton";

export function isCalibration(doc: ISearchDocumentModel) {
    return doc.nestedItems
        ?.map((x) => {
            const parts = x.id.split("/");

            return parts[3] === "0" && parts[4] === "1";
        })
        .includes(true);
}

function getSelectionState(
    selection: ISelectedItem[],
    id: IDocIdInfo,
): IItemSelectionState {
    const item = selection.find(
        (x) => x.id === id.id && x.prefix === id.prefix,
    );

    if (!item) {
        return null;
    }

    if (!item.nested.length) {
        return true;
    }

    return item.nested;
}

export interface ISearchResultProps {
    project: number;

    sortBy: ESortColumns;
    isAscending: boolean;
    items: ISearchDocumentModel[];

    selection: ISelectedItem[];

    onSelected(selection: ISelectedItem[]): void;
    applySort(sortBy: ESortColumns, isAscending: boolean): void;
}

interface ISearchResultState {
    isExpandeds: boolean[];
}

class SearchResult extends React.PureComponent<
    ISearchResultProps,
    ISearchResultState
> {
    public readonly state: ISearchResultState = {
        isExpandeds: [],
    };

    public static getDerivedStateFromProps(
        props: ISearchResultProps,
        state: ISearchResultState,
    ): Partial<ISearchResultState> {
        if (props.items.length === state.isExpandeds.length) {
            return {};
        }

        return {isExpandeds: props.items.map(() => false)};
    }

    private get searchItems() {
        const {items, project} = this.props;

        return items.map((doc) => ({
            doc,
            id: getDocIdInfo(doc.docId, project, isCalibration(doc)),
        }));
    }

    private get checkState() {
        const {selection} = this.props;
        if (selection.length === 0) {
            return ECheckStates.NotChecked;
        }

        if (selection.length < this.searchItems.length) {
            return ECheckStates.Indeterminate;
        }

        for (const item of selection) {
            if (item.nested.length) {
                return ECheckStates.Indeterminate;
            }
        }

        return ECheckStates.Checked;
    }

    @boundMethod
    public onSelectDocumentType(type: string) {
        const selection: ISelectedItem[] = this.searchItems
            .filter((document) => document.doc.types.includes(type))
            .map(({id, doc}) => ({
                device: id.device,
                id: id.id,
                nested: [],
                owner: doc.owner,
                prefix: id.prefix,
            }));

        this.props.onSelected(selection);
    }

    @boundMethod
    public toggleAll(selectAll: boolean) {
        if (selectAll) {
            const selection = this.searchItems.map(({id, doc}) => ({
                device: id.device,
                id: id.id,
                nested: [],
                owner: doc.owner,
                prefix: id.prefix,
            }));
            this.props.onSelected(selection);
            return;
        }

        this.props.onSelected([]);
    }

    @boundMethod
    public onSelected(
        id: IDocIdInfo,
        doc: ISearchDocumentModel,
        value: IItemSelectionState,
    ) {
        const {onSelected, selection} = this.props;
        const updated = selection.filter(
            (x) => x.id !== id.id || x.prefix !== id.prefix,
        );

        if (value !== null) {
            const nested = value === true ? [] : value;
            updated.push({
                device: id.device,
                id: id.id,
                nested,
                owner: doc.owner,
                prefix: id.prefix,
            });
        }

        onSelected(updated);
    }

    @boundMethod
    public onExpanding(isExpanded: boolean, index: number) {
        const isExpandeds = [...this.state.isExpandeds];
        isExpandeds[index] = isExpanded;

        this.setState({isExpandeds});
    }

    @boundMethod
    public toggleAllExpand(e: React.SyntheticEvent) {
        e.preventDefault();

        const {isExpandeds} = this.state;
        this.setState({
            isExpandeds: isExpandeds.every((x) => x)
                ? isExpandeds.map(() => false)
                : isExpandeds.map(() => true),
        });
    }

    public render() {
        const {applySort, isAscending, sortBy} = this.props;
        const {isExpandeds} = this.state;
        const sortProps = {
            active: sortBy,
            ascending: isAscending,
            onSorted: applySort,
        };

        return (
            <table className="table table-sm table-striped table-borderless mb-2">
                <colgroup>
                    <col span={3} style={{width: "1px"}} />
                    <col style={{width: "50%"}} />
                </colgroup>

                <thead>
                    <tr>
                        <th colSpan={2}>
                            <TriStateSplitButton
                                value={this.checkState}
                                onClick={this.toggleAll}
                                onSelectType={this.onSelectDocumentType}
                            />
                        </th>
                        <th onClick={this.toggleAllExpand}>
                            <FontAwesomeIcon
                                icon={
                                    !isExpandeds.every((x) => x)
                                        ? faPlusSquare
                                        : faMinusSquare
                                }
                                fixedWidth={true}
                            />
                        </th>
                        <th>
                            <SortHeader<ESortColumns>
                                {...sortProps}
                                name={ESortColumns.Name}
                            >
                                <T>Title</T>
                            </SortHeader>
                        </th>
                        <th>
                            <T>Type</T>
                        </th>
                        <th>
                            <SortHeader<ESortColumns>
                                {...sortProps}
                                isNumber={true}
                                name={ESortColumns.Modified}
                            >
                                <T>Saved</T>
                            </SortHeader>
                        </th>
                        <th>
                            <SortHeader<ESortColumns>
                                {...sortProps}
                                isNumber={true}
                                name={ESortColumns.Created}
                            >
                                <T>Created</T>
                            </SortHeader>
                        </th>
                    </tr>
                </thead>

                {this.renderTableBody(this.searchItems)}
            </table>
        );
    }

    private renderTableBody(items: ISearchItem[]) {
        return (
            <tbody>
                <AnimatePresence exitBeforeEnter={true}>
                    {this.renderItems(items)}
                </AnimatePresence>
            </tbody>
        );
    }

    private renderItems(items: ISearchItem[]) {
        const {selection, project} = this.props;
        const {isExpandeds} = this.state;

        return items.map((item, index) => (
            <motion.tr
                key={index}
                animate={onOpen}
                exit={onClosed}
                initial={onClosed}
                transition={animation}
            >
                <SearchItem
                    project={project}
                    item={item}
                    isExpanded={isExpandeds[index]}
                    index={index}
                    selection={getSelectionState(selection, item.id)}
                    onSelected={this.onSelected}
                    onExpanding={this.onExpanding}
                />
            </motion.tr>
        ));
    }
}

export default SearchResult;
