import React from "react";

import T from "@translate/T";

interface INotesProps {
    value: string;
    suffixId: string;

    withEdit?: boolean;
}

class Notes extends React.PureComponent<INotesProps> {
    public render() {
        const {value, suffixId, children, withEdit} = this.props;
        const id = "notes-" + suffixId;
        const className = !withEdit ? "w-100" : undefined;

        if (value) {
            return (
                <div className={className} id={id} data-testid={id}>
                    {value}
                </div>
            );
        }

        if (children) {
            return children;
        }

        return (
            <div id={id} data-testid={id}>
                <em className="text-muted">
                    <T>No notes set</T>
                </em>
            </div>
        );
    }
}

export default Notes;
