import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import session from "@/services/session";
import sleep from "@/services/sleep";

export interface IMultipleFormSubmitProps {
    method: "POST" | "GET";
    actions: string[];

    className: string;
    icon: IconDefinition;
    idSuffix: string;
    name?: JSX.Element;

    dataTestId?: string;
    title?: string;
}

class MultipleFormSubmit extends React.PureComponent<IMultipleFormSubmitProps> {
    private formRefs: React.RefObject<HTMLFormElement>[] = [];

    @boundMethod
    public async onClick() {
        for (const ref of this.formRefs) {
            ref.current?.submit();
            await sleep(4);
        }
    }

    public render() {
        const {className, dataTestId, icon, name, actions, idSuffix, title} =
            this.props;
        this.formRefs = actions.map((_) => React.createRef<HTMLFormElement>());
        const id = "multiple-form-submit-" + idSuffix;

        return (
            <React.Fragment>
                {this.formRefs.map((ref, i) => this.renderForm(ref, i))}
                <button
                    type="button"
                    id={id}
                    data-testid={dataTestId ?? id}
                    className={className}
                    title={title}
                    onClick={this.onClick}
                >
                    <FontAwesomeIcon
                        icon={icon}
                        fixedWidth={true}
                        className={!name ? "mr-1" : undefined}
                    />
                    {name ?? null}
                </button>
            </React.Fragment>
        );
    }

    private renderForm(ref: React.RefObject<HTMLFormElement>, i: number) {
        const {actions, children, method} = this.props;

        return (
            <form
                key={i}
                ref={ref}
                method={method}
                action={actions[i]}
                className="d-none"
            >
                <input type="hidden" name="token" value={session.token ?? ""} />
                {children}
            </form>
        );
    }
}

export default MultipleFormSubmit;
