import {floor} from "lodash";
import {IntlShape} from "react-intl";

import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import license from "@/services/license";
import signal from "@/services/signal";
import {
    DUMMY_DEVICE_ID,
    IDevice,
    IDeviceId,
    ILicensedDevice,
} from "@toolbox/models";

import {
    device2DeviceApi,
    getDeviceDisplayName,
} from "@toolbox/functions/device-check";
import {reMapObjects} from "@toolbox/functions/object-iterator";
import {intl2Str, parseNumber} from "@translate/T";

export function compressDevice<TValue extends IDeviceId>(
    device: TValue,
): IDeviceId {
    return reMapObjects<TValue, IDeviceId>(device, DUMMY_DEVICE_ID);
}

interface IEventHandler {
    devicesChanged?(devices: IDeviceStatus[]): void;
}

export interface ILastMeasurement {
    id: number;
    name: string;
    project: number;
}

export interface IDeviceStatus {
    device: IDeviceId;
    state: number;
    lastMessage: string;
    lastMeasurement: ILastMeasurement | null;

    isOnline: boolean;
}

export interface INetworkDeviceConfig {
    ipAddresses: string[];
    port: number;
}

class NetworkDevicesService {
    private readonly events = new EventHandlers<IEventHandler>();

    public config: INetworkDeviceConfig = {ipAddresses: [], port: 9000};
    private _devices: IDeviceStatus[] = [];

    public constructor() {
        signal.register({
            devicesChanged: (devices) => {
                this._devices = devices;
                this.events.publish((x) => x.devicesChanged?.(devices));
            },
        });
    }

    public get devices() {
        return this._devices;
    }

    public getDeviceName(intl: IntlShape, device: IDeviceId | ILicensedDevice) {
        const name = getDeviceDisplayName(device.deviceClass);

        // if fabrication is 0 and series ends with 0, it is considered a "series"
        if (this.isSeries(device)) {
            const series = floor(device.series / 10);

            return intl2Str(intl, "{name} series {series}", {name, series});
        }

        return `${name} ${this.deviceIdString(device)}`;
    }

    public deviceIdString(value: IDevice) {
        return `${value.series}${value.designCode ?? ""}-${value.fabrication}`;
    }

    // if fabrication is 0 and series ends with 0, it is considered a "series"
    public isSeries(device: IDevice) {
        return device.fabrication === 0 && device.series % 10 === 0;
    }

    public sameDevice(device1: IDevice, device2: IDevice) {
        return this.deviceIdString(device1) === this.deviceIdString(device2);
    }

    public sameSeries(device1: IDevice, device2: IDevice) {
        return floor(device1.series / 10) === floor(device2.series / 10);
    }

    public isGenericSameDevice(generic: IDevice, device: IDevice) {
        return this.isSeries(generic) && this.sameSeries(generic, device);
    }

    // a way to convert it back
    public getDeviceId(value: string) {
        const parts = value.split("-");

        const device: IDevice = {
            designCode: parts[0].replace(/[0-9]/g, ""),
            series: parseNumber(parts[0].replace(/[A-Za-z]/g, "")),
            fabrication: parseNumber(parts[1]),
        };

        return device;
    }

    public isLicensed(device: IDevice) {
        return !!license.status.devices.find(
            (x) =>
                this.sameDevice(x, device) ||
                this.isGenericSameDevice(x, device),
        );
    }

    public canBeExtracted(device: IDeviceId) {
        return license.getDeviceLicenses()[
            device2DeviceApi(device.deviceClass)
        ];
    }

    public findBestSuitedDevice(devices: IDeviceId[], value?: IDeviceId) {
        if (!devices.length) {
            return undefined;
        }

        if (!value) {
            return devices[0];
        }

        // find the one
        const exact = devices.find((x) => networkDevices.sameDevice(x, value));
        if (exact) {
            return exact;
        }

        // same generic (first 3 numbers are the same)
        const sameGeneric = devices.find((x) =>
            networkDevices.isGenericSameDevice(x, value),
        );
        if (sameGeneric) {
            return sameGeneric;
        }

        // same deviceClass
        const sameClass = devices.find(
            (x) => x.deviceClass === value.deviceClass,
        );
        if (sameClass) {
            return sameClass;
        }

        return devices[0];
    }

    public sort(intl: IntlShape, _devices: (IDeviceId | ILicensedDevice)[]) {
        const devices = [..._devices];
        const series = devices.filter(this.isSeries);
        const rest = devices.filter((x) => !this.isSeries(x));
        const sorter = (x: (IDeviceId | ILicensedDevice)[]) =>
            x.sort((a, b) =>
                networkDevices
                    .getDeviceName(intl, a)
                    .localeCompare(networkDevices.getDeviceName(intl, b)),
            );

        return sorter(series).concat(sorter(rest));
    }

    public async retrieveConfig() {
        try {
            const config = await http
                .get("/api/devices/networkconfig", {cache: "no-cache"})
                .json<INetworkDeviceConfig>();

            this.config = config;

            return config;
        } catch {
            return null;
        }
    }

    public async retrieveDevices() {
        try {
            const devices = await http
                .get("/api/devices/status", {cache: "no-cache"})
                .json<IDeviceStatus[]>();

            this._devices = devices; // deviceStatus
        } catch {
            this._devices = [];
        }

        this.events.publish((x) => x.devicesChanged?.(this._devices));
    }

    public silentReset() {
        this.config = {ipAddresses: [], port: 9000};
        this._devices = [];
    }

    public subscribe(handler: IEventHandler) {
        return this.events.register(handler);
    }
}

const networkDevices = new NetworkDevicesService();
export default networkDevices;
