import {faEdit, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

import {EModuleLicenses} from "@/components/license/models";
import license from "@/services/license";
import {ERoles, ILicenses} from "@/services/models";
import session from "@/services/session";
import {ANY_TRUE} from "@toolbox/functions/models";
import {EDeviceClasses} from "@toolbox/models";
import {IEnabledDocuments, ISelectedItem} from "./models";
import {ICombineModel} from "./task-buttons/models";

import ActivityFlyoutButton from "@toolbox/building-blocks/ActivityFlyoutButton";
import TasksPanel from "@toolbox/building-blocks/TasksPanel";
import {
    canCombine,
    hasRotation,
    isCentEngine,
    isFracEngine,
    isSpocDevice,
} from "@toolbox/functions/device-check";
import {searchObjectsSome} from "@toolbox/functions/object-iterator";
import T, {intl2Str, parseNumber} from "@translate/T";
import AstmModal from "./task-buttons/AstmModal";
import CentAnalyzeButton from "./task-buttons/CentAnalyzeButton";
import CombineButton from "./task-buttons/CombineButton";
import CreateSopButton from "./task-buttons/CreateSopButton";
import DocumentDelete from "./task-buttons/DocumentDelete";
import ExportButton from "./task-buttons/ExportButton";
import FracAnalyzeButton from "./task-buttons/FracAnalyzeButton";
import SpocAnalyzeButton from "./task-buttons/SpocAnalyzeButton";

export function renderCreateSopButton(
    project: number,
    className?: string,
    isButtonGroup?: boolean,
) {
    if (!session.hasRole(ERoles.Editor, project)) {
        return null;
    }

    const licenses = license.getDeviceLicenses();
    if (!searchObjectsSome<ILicenses>(licenses, ANY_TRUE)) {
        return null;
    }

    return (
        <CreateSopButton
            className={className}
            project={project}
            licenses={licenses}
            isButtonGroup={isButtonGroup}
        />
    );
}

export function renderManageButton(
    intl: IntlShape,
    project: number,
    className: string = "",
    show: boolean = true,
) {
    if (!session.hasRole(ERoles.Manager, project)) {
        return null;
    }

    return (
        <Link
            id="manage-project"
            className={!show ? "btn-group" : undefined}
            aria-label={"edit-" + project}
            title={intl2Str(intl, "Manage project")}
            to={`/project/${project}/edit`}
        >
            <button type="button" className={"btn btn-warning" + className}>
                <FontAwesomeIcon
                    icon={faEdit}
                    fixedWidth={true}
                    className={show ? "mr-1" : undefined}
                />
                {show && <T>Manage</T>}
            </button>
        </Link>
    );
}

export function renderImportButton(
    intl: IntlShape,
    project: number,
    className: string = "",
    show: boolean = true,
) {
    if (!session.hasRole(ERoles.Editor, project)) {
        return null;
    }

    return (
        <Link
            id="import-project"
            className={!show ? "btn-group" : undefined}
            title={intl2Str(intl, "import documents")}
            to={`/project/${project}/import`}
        >
            <button type="button" className={"btn btn-secondary" + className}>
                <FontAwesomeIcon
                    icon={faUpload}
                    fixedWidth={true}
                    className={show ? "mr-1" : undefined}
                />
                {show && <T>Import</T>}
            </button>
        </Link>
    );
}

export interface ISearchProps {
    intl: IntlShape;
    project: number;

    name: string;
    selection: ISelectedItem[];

    onDeleted(): void;
    getItemName(id: string): string;
    onDelete(undeletedIds: string[]): void;
}

class SearchButtons extends React.PureComponent<ISearchProps> {
    private get targets(): IEnabledDocuments | undefined {
        const {selection} = this.props;
        const devices: EDeviceClasses[] = [];
        const params = new URLSearchParams();

        for (const item of selection) {
            if (typeof item.device !== "number") {
                // not a measurement
                return;
            }

            devices.push(item.device);

            const nested = item.nested;
            if (!nested.length) {
                // measurement selected
                params.append("m", item.id);
                continue;
            }

            for (const nestedId of nested) {
                // nested selected
                params.append("s", nestedId);
            }
        }

        return {devices, params: params.toString()};
    }

    public render() {
        const {selection} = this.props;
        const targets = this.targets;

        if (!selection.length) {
            return (
                <TasksPanel>
                    {this.renderImportButton()}
                    {this.renderSopButton()}
                    {this.renderManageButton()}
                    {this.renderAstmButton(targets)}

                    <div className="ml-auto">
                        <ActivityFlyoutButton />
                    </div>
                </TasksPanel>
            );
        }

        return (
            <TasksPanel>
                {this.renderExport()}
                {this.renderFractureAnalyze(targets)}
                {this.renderCentrifugeAnalyze(targets)}
                {this.renderSpocAnalyze(targets)}
                {this.renderCombine()}
                {this.renderDelete()}

                <div className="ml-auto">
                    <ActivityFlyoutButton />
                </div>
            </TasksPanel>
        );
    }

    private renderImportButton() {
        const {intl, project} = this.props;
        const button = renderImportButton(intl, project);
        if (!button) {
            return null;
        }

        return (
            <div id="import" className="mr-1">
                {button}
            </div>
        );
    }

    private renderSopButton() {
        const {project} = this.props;
        const button = renderCreateSopButton(project);
        if (!button) {
            return null;
        }

        return (
            <div id="sop" className="dropdown mr-1">
                {button}
            </div>
        );
    }

    private renderManageButton() {
        const {intl, project} = this.props;
        const button = renderManageButton(intl, project);
        if (!button) {
            return null;
        }

        return (
            <div id="manage" className="mr-1">
                {button}
            </div>
        );
    }

    private renderAstmButton(data?: IEnabledDocuments) {
        if (
            !license.status.modules.includes(EModuleLicenses.Astm) ||
            !data ||
            !data.devices.every((device) => device === EDeviceClasses.Reader)
        ) {
            return null;
        }

        return <AstmModal id={this.props.project} />;
    }

    private renderExport() {
        const {project, selection, name} = this.props;

        return (
            <ExportButton
                idSuffix="search"
                className="mr-1"
                name={name}
                project={project}
                selection={selection}
            />
        );
    }

    private renderFractureAnalyze(data?: IEnabledDocuments) {
        const {project} = this.props;
        if (!data || !data.devices.every(isFracEngine)) {
            return null;
        }

        return <FracAnalyzeButton params={data.params} project={project} />;
    }

    private renderCentrifugeAnalyze(data?: IEnabledDocuments) {
        if (!data || !data.devices.every(isCentEngine)) {
            return null;
        }

        const onlyReader = data.devices.every(hasRotation);
        const onlySizerFuge = !data.devices.some(hasRotation);
        if (!onlyReader && !onlySizerFuge) {
            return null;
        }

        return (
            <CentAnalyzeButton
                className="mr-1"
                targets={data.params}
                project={this.props.project}
            />
        );
    }

    private renderSpocAnalyze(data?: IEnabledDocuments) {
        if (!data || !data.devices.every(isSpocDevice)) {
            return null;
        }

        return (
            <SpocAnalyzeButton
                className="mr-1"
                targets={data.params}
                project={this.props.project}
            />
        );
    }

    private renderCombine() {
        const {project} = this.props;
        if (!session.hasRole(ERoles.Manager, project)) {
            return null;
        }

        const data = this.getCombineTargets();
        if (!data || !canCombine(data.device)) {
            return null;
        }

        return <CombineButton model={data} />;
    }

    private renderDelete() {
        const {onDelete, onDeleted, project, selection} = this.props;

        return (
            <DocumentDelete
                project={project}
                onDeleted={onDeleted}
                onDelete={onDelete}
                selection={selection}
            />
        );
    }

    private getCombineTargets(): ICombineModel | undefined {
        const {getItemName, selection, project} = this.props;
        let name = "";
        const ids: number[] = [];
        let device: EDeviceClasses | undefined;

        if (selection.length < 2) {
            return;
        }

        for (const item of selection) {
            if (item.device === undefined) {
                // not a measurement
                return;
            }

            if (device === undefined) {
                device = item.device;
            } else if (item.device !== device) {
                // measurement of a different device type
                return;
            }

            if (item.nested.length) {
                // only part of measurement is selected (iE: 1 of 3 samples)
                return;
            }

            ids.push(parseNumber(item.id));

            if (!name) {
                name = getItemName(item.id);
            }
        }

        return {name, device: device as EDeviceClasses, ids, project};
    }
}

export default injectIntl(SearchButtons);
