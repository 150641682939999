import {EConnection} from "@/components/preferences/models";
import {EModules} from "@/models";
import {EServerId} from "@shared/models";
import {EDeviceString} from "@shared/sop/models";
import {EDeviceClasses, IDeviceClasses, IDeviceId} from "@toolbox/models";

import {getDeviceConnection} from "@/components/preferences/DeviceConnection";
import {isSpoc} from "./engine-check";

// display name to user
export function getDeviceDisplayName(
    type: EDeviceClasses | IDeviceClasses,
): string {
    if (typeof type === "string") {
        return type;
    }

    switch (type) {
        case EDeviceClasses.Fracture:
            return "LUMiFrac";

        case EDeviceClasses.Fuge:
            return "LUMiFuge";

        case EDeviceClasses.Reader:
            return "LUMiReader";

        case EDeviceClasses.XRay:
            return "LUMiReader X-Ray";

        case EDeviceClasses.Sizer:
        case EDeviceClasses.SizerMwl:
        case EDeviceClasses.SizerNano:
            return "LUMiSizer";

        case EDeviceClasses.Spoc:
            return "LUMiSpoc";
    }
}

export function deviceName2Device(device: IDeviceClasses): EDeviceClasses {
    switch (device) {
        case "LUMiFrac":
            return EDeviceClasses.Fracture;

        case "LUMiFuge":
            return EDeviceClasses.Fuge;

        case "LUMiReader":
            return EDeviceClasses.Reader;

        case "LUMiReader X-Ray":
            return EDeviceClasses.XRay;

        case "LUMiSizer":
            return EDeviceClasses.Sizer;

        case "LUMiSpoc":
            return EDeviceClasses.Spoc;
    }
}

export function convertString2Device(name: string): EDeviceClasses {
    const enums = Object.values(EDeviceClasses).filter(
        (x) => typeof x === "string",
    );

    const value = enums.indexOf(name);
    if (value !== -1) {
        return value;
    }

    return EDeviceClasses.Reader;
}

export function device2DeviceApi(
    value: EDeviceClasses | undefined,
): EDeviceString {
    switch (value) {
        case EDeviceClasses.Fracture:
            return EDeviceString.Frac;

        case EDeviceClasses.Fuge:
            return EDeviceString.Fuge;

        default:
        case EDeviceClasses.Reader:
            return EDeviceString.Reader;

        case EDeviceClasses.XRay:
            return EDeviceString.XRay;

        case EDeviceClasses.Sizer:
        case EDeviceClasses.SizerMwl:
        case EDeviceClasses.SizerNano:
            return EDeviceString.Sizer;

        case EDeviceClasses.Spoc:
            return EDeviceString.Spoc;
    }
}

export function deviceApi2Device(
    device: EDeviceString | undefined,
): EDeviceClasses | undefined {
    switch (device) {
        case EDeviceString.Frac:
            return EDeviceClasses.Fracture;

        case EDeviceString.Fuge:
            return EDeviceClasses.Fuge;

        case EDeviceString.Reader:
            return EDeviceClasses.Reader;

        case EDeviceString.XRay:
            return EDeviceClasses.XRay;

        case EDeviceString.Sizer:
            return EDeviceClasses.Sizer;

        case EDeviceString.Spoc:
            return EDeviceClasses.Spoc;

        default:
            return undefined;
    }
}

export function device2ServerId(value?: EDeviceClasses): EServerId {
    switch (device2DeviceApi(value)) {
        default:
        case EDeviceString.Reader:
        case EDeviceString.Fuge:
        case EDeviceString.Sizer:
        case EDeviceString.XRay:
            return EServerId.Centrifuge;

        case EDeviceString.Frac:
            return EServerId.Fracture;

        case EDeviceString.Spoc:
            return EServerId.Spoc;
    }
}

export function engine2ServerId(engine: EModules) {
    if (engine === EModules.Frac) {
        return EServerId.Fracture;
    } else if (isSpoc(engine)) {
        return EServerId.Spoc;
    } else {
        return EServerId.Centrifuge;
    }
}

// checks if the device uses light
export function hasLightFactor(type: EDeviceClasses) {
    switch (type) {
        case EDeviceClasses.XRay:
            return false;

        default:
            return true;
    }
}

// determines if the specified has multiple opticals (wavelengths)
export function hasMultipleOpticals(type: EDeviceClasses) {
    switch (type) {
        case EDeviceClasses.Reader:
        case EDeviceClasses.XRay:
        case EDeviceClasses.SizerMwl:
        case EDeviceClasses.SizerNano:
            return true;

        default:
            return false;
    }
}

export function hasNoCable(device: IDeviceId) {
    const reader = 4520 <= device.series && device.series <= 4529;
    const xray = 4400 <= device.series && device.series <= 4499;

    return reader || xray;
}

// checks wether the device has the ability to work with temperature change
export function hasTemperatureControl(device: IDeviceId) {
    switch (device.deviceClass) {
        case EDeviceClasses.XRay:
            const deviceConnection = getDeviceConnection(device);
            return (
                hasNoCable(device) || // has to be network
                (!!deviceConnection && // has to be registered in prefs
                    deviceConnection.connection !== EConnection.Usb) // has to be network
            );

        default:
            return true;
    }
}

// determines if the specified device uses centrifgual force (true), measured in rotations per minute (RPM)
export function hasRotation(type: EDeviceClasses) {
    switch (type) {
        case EDeviceClasses.Reader:
        case EDeviceClasses.XRay:
        case EDeviceClasses.Spoc:
            return false;

        default:
            return true;
    }
}

// determines if the specified device only has one channel
export function hasSingleChannel(type: EDeviceClasses) {
    switch (type) {
        case EDeviceClasses.Reader:
        case EDeviceClasses.XRay:
            return true;

        default:
            return false;
    }
}

// determines if the specified device uses gravity, measured in tilt degree (°)
export function hasTilt(type: EDeviceClasses) {
    switch (type) {
        case EDeviceClasses.Reader:
            return true;

        default:
            return false;
    }
}

export function canCombine(type: EDeviceClasses) {
    switch (type) {
        case EDeviceClasses.Reader:
        case EDeviceClasses.XRay:
            return true;

        default:
            return false;
    }
}

export function isCentEngine(value: EDeviceClasses) {
    return device2ServerId(value) === EServerId.Centrifuge;
}

export function isFracEngine(value: EDeviceClasses) {
    return value === EDeviceClasses.Fracture;
}

export function isSpocDevice(value: EDeviceClasses) {
    return value === EDeviceClasses.Spoc;
}
