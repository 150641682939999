import {round} from "lodash";
import {IntlShape} from "react-intl";

import {getSepviewVersion} from "@/Footer";
import {intl2Str} from "@translate/T";

export const GREATER_ZERO = (value: number) => Math.max(value, 0.01);
export const ROUND_ZERO = (value: number) => round(value, 0);
export const CHECK_FOR_NAN = (value: number) => isNaN(value);
export const NOT_NAN = (value: unknown) =>
    typeof value === "number" ? !isNaN(value) : true;
export const IS_UNDEFINED = (value: unknown | undefined) => value === undefined;
export const NOT_UNDEFINED = (value: unknown | undefined) =>
    value !== undefined;
export const ANY_TRUE = (value: boolean) => value;
export const ANY_FALSE = (value: boolean) => !value;
export const CHECK_FOR_NAN_BUT_IGNORE_UNDEFINED = (value: number | undefined) =>
    value !== undefined ? CHECK_FOR_NAN(value) : false;
export const NOT_UNDEFINED_NOT_NAN = (value: unknown | undefined) =>
    NOT_UNDEFINED(value) && NOT_NAN(value);

export const DEVICE_VERSION_CSV_EXPORT = (intl: IntlShape) => [
    intl2Str(intl, "Version"),
    getSepviewVersion(),
];

export interface IStatistics {
    range: number;
    min: number;
    max: number;
    stdDev: number;
    mean: number;
    meanStdDev: number;
    uncertainty: number;
    samples: number;
}

export type INumberOrNaN = number | "NaN" | "-Infinity" | "Infinity";
export type ICsvRow = string[];
export type ICsvData = ICsvRow[];
