import {DEFAULT_LANGUAGE} from "@/components/navbar/models";
import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import {STORAGE_KEY} from "./models";

import {getValidIntl} from "@translate/Provider";

interface IEventHandler {
    localeChanged(
        language: string,
        messages: Record<string, string> | undefined,
    ): void;
}

class IntlLocaleService {
    private readonly handlers = new EventHandlers<IEventHandler>();

    private _language: string = DEFAULT_LANGUAGE.id; // https://registry-page.isdcf.com/languages/
    private _messages: Record<string, string> | undefined;
    private readonly cachedMessages = new Map<
        string,
        Record<string, string> | undefined
    >();

    public get language() {
        return this._language; // nobody is allowed to read this but Provider
    }

    public get messages() {
        return this._messages;
    }

    public async loadLanguage(_language?: string) {
        const language = getValidIntl(
            _language ??
                localStorage.getItem(STORAGE_KEY) ??
                DEFAULT_LANGUAGE.id,
        );
        if (language === this._language) {
            return;
        }

        this._messages = await this.getMessages(language);
        this._language = language;
        document.documentElement.lang = this._language;
        localStorage.setItem(STORAGE_KEY, language);

        this.handlers.publish((x) =>
            x.localeChanged?.(language, this._messages),
        );
    }

    // fake intl custom creation
    public async translateSingleString(language: string, translate: string) {
        const messages = await this.getMessages(language);

        try {
            const singl = messages[translate];
            return singl ? singl : translate; // fallback if present in pot-file, but not translated yet
        } catch {
            return translate; // fallback if not present in pot-file, hence it crashes
        }
    }

    // will be called after user has put new translation intp manual drag&drop input
    public async resetCache() {
        this.cachedMessages.clear();
        await this.loadLanguage();
    }

    // IMPORTANTE: nobody but Provider.tsx will sub to this !!
    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }

    private async getMessages(language: string) {
        if (!this.cachedMessages.has(language)) {
            await this.download(language);
        }

        return this.cachedMessages.get(language)!;
    }

    private async download(language: string) {
        if (language === DEFAULT_LANGUAGE.id) {
            return;
        }

        try {
            const messages = await http
                .get("/api/languages/" + language)
                .json<Record<string, string>>();

            this.cachedMessages.set(language, messages);
        } catch {
            this.cachedMessages.delete(language);
        }
    }
}

const intlLocale = new IntlLocaleService();
export default intlLocale;
