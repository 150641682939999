import React from "react";
import {NavLink} from "react-router-dom";

import {EModuleLicenses, ESopLicenses} from "@/components/license/models";
import license, {ILicenseStatus} from "@/services/license";
import {INavLink} from "@/services/models";
import navLinks from "@/services/nav-links";
import resize from "@/services/resize";
import session from "@/services/session";

import T from "@translate/T";

interface INavLinksState {
    authenticated: boolean;
    licenseStatus: ILicenseStatus;
    links: INavLink[];
}

class NavLinks extends React.PureComponent<{}, INavLinksState> {
    public readonly state: INavLinksState = {
        authenticated: !!session.token,
        licenseStatus: license.status,
        links: navLinks.items,
    };

    private readonly div = React.createRef<HTMLDivElement>();

    private unsubscribe?: () => void;

    public componentDidMount() {
        const unLinks = navLinks.subscribe({
            navLinkChanged: (links) => this.setState({links}),
        });
        const unSession = session.subscribe({
            authenticated: () => this.setState({authenticated: true}),
            expired: () => this.setState({authenticated: false}),
        });
        const unLicense = license.subscribe({
            licenseChanged: (licenseStatus) => this.setState({licenseStatus}),
        });
        const unResize = resize.subscribe(this.div.current!, () =>
            this.forceUpdate(),
        );

        this.unsubscribe = () => {
            unLicense();
            unLinks();
            unResize();
            unSession();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        return (
            <div ref={this.div} className="w-100">
                <div className="big-width-cell mr-auto">
                    <ul
                        className="navbar-nav"
                        style={{width: this.div.current?.clientWidth}}
                    >
                        {this.renderItems()}
                    </ul>
                </div>
            </div>
        );
    }

    private renderItems() {
        const {links} = this.state;
        const itemLinks = links?.length ? links : this.getDefaultLinks();
        const singl = itemLinks.length === 1;

        return itemLinks.map((itemLink) => (
            <li
                key={itemLink.url}
                className={"nav-item" + (singl ? " w-100" : "")} // do not add w-100 if there are multiple. it looks awefull
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
            >
                <NavLink
                    className="nav-link d-flex"
                    to={itemLink.url}
                    exact={true}
                >
                    <span className={singl ? "text-dot-overflow" : ""}>
                        {itemLink.text}
                    </span>
                </NavLink>
            </li>
        ));
    }

    private getDefaultLinks() {
        const {authenticated, licenseStatus} = this.state;
        if (!authenticated) {
            return [];
        }

        const {sops, modules} = licenseStatus;
        const devices = license.getDeviceLicenses();
        const links = [];

        if (devices.frac) {
            links.push({text: <T>Test stamps</T>, url: "/stamps"});
        }

        if (devices.cent || devices.spoc) {
            links.push({text: <T>Materials</T>, url: "/materials"});
        }

        if (modules.includes(EModuleLicenses.Hindrance)) {
            links.push({text: <T>Hindrance function</T>, url: "/hindrance"});
        }

        if (sops.includes(ESopLicenses.Template)) {
            links.push({text: <T>Templates</T>, url: "/templates"});
        }

        if (devices.frac || devices.cent) {
            links.push({text: <T>Downloads</T>, url: "/downloads"});
        }

        if (devices.frac || devices.fuge || devices.sizer) {
            links.push({text: <T>RCA calculator</T>, url: "/rca"});
        }

        return links;
    }
}

export default NavLinks;
