import {
    faFileDownload,
    faFileExcel,
    faHardDrive,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {EModuleLicenses} from "@/components/license/models";
import http from "@/services/http";
import license from "@/services/license";
import loading from "@/services/loading";
import {EServerId} from "../models";

import {getSepviewVersion} from "@/Footer";
import DropdownButton from "@toolbox/button-like/DropdownButton";
import MultipleFormSubmit from "@toolbox/button-like/MultipleFormSubmit";
import T, {intl2Str} from "@translate/T";

interface IDropdownExportProps {
    storeApi: string;
    sampleIds?: string[];
    serverId?: EServerId; // only send server id if wanted more than server store export

    disabled?: boolean;

    getJson(): Promise<object>;
}

class DropdownExport extends React.PureComponent<IDropdownExportProps> {
    @boundMethod
    public async onClick() {
        const {storeApi, getJson} = this.props;

        try {
            const json = (await getJson?.()) ?? {};
            const response = await http.post(storeApi, {json, timeout: false});
            if (!response.ok) {
                throw new http.HTTPError(response);
            }
        } catch (error) {
            loading.catchApiAbort(error);
        }
    }

    public render() {
        return <IntlContext.Consumer children={this.renderButton} />;
    }

    @boundMethod
    private renderButton(intl: IntlShape) {
        const {serverId} = this.props;
        const menu = () => this.renderMenu(intl); // need this for updating, if samples get added.

        return (
            <DropdownButton
                type="primary"
                idSuffix={serverId + "-main-export"}
                classNameButton="dropdown-toggle-split"
                menu={menu}
            />
        );
    }

    @boundMethod
    private renderMenu(intl: IntlShape) {
        const {children, disabled, sampleIds, serverId} = this.props;
        const aucActions = (sampleIds ?? []).map(
            (x) => `/api/${serverId}/measurement/${x}/auc`,
        );
        const fcsActions = (sampleIds ?? []).map(
            (x) => `/api/${serverId}/measurement/${x}/fcs`,
        );
        const csvActions = (sampleIds ?? []).map(
            (x) => `/api/${serverId}/measurement/${x}/export`,
        );

        let csvTitle = <T>Export samples to CSV</T>;
        if (serverId === EServerId.Fracture) {
            csvTitle = <T>Export specimens to Excel</T>; // special title for frac
        }

        return (
            <React.Fragment>
                <button
                    type="button"
                    className="dropdown-item"
                    disabled={disabled}
                    title={intl2Str(
                        intl,
                        `Stored in server storage directory sub folder "Exports".`,
                    )}
                    onClick={this.onClick}
                >
                    <FontAwesomeIcon
                        icon={faHardDrive}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Store on server</T>
                </button>

                {serverId === EServerId.Centrifuge &&
                    license.status.modules.includes(
                        EModuleLicenses.AnalyticalUltraCentrifugation,
                    ) && (
                        <MultipleFormSubmit
                            actions={aucActions}
                            className="dropdown-item"
                            icon={faFileDownload}
                            idSuffix="dropdown-auc-export"
                            method="POST"
                            name={<T>Export to AUC</T>}
                        />
                    )}

                {serverId === EServerId.Spoc && (
                    <MultipleFormSubmit
                        actions={fcsActions}
                        className="dropdown-item"
                        icon={faFileDownload}
                        idSuffix="dropdown-fsc-export"
                        method="POST"
                        name={<T>Export to FCS</T>}
                    />
                )}

                {!!serverId && (
                    <MultipleFormSubmit
                        actions={csvActions}
                        className="dropdown-item"
                        icon={faFileExcel}
                        idSuffix="dropdown-export"
                        method="POST"
                        name={csvTitle}
                    >
                        <input
                            type="hidden"
                            name="language"
                            value={intl.locale}
                        />
                        <input
                            type="hidden"
                            name="offset"
                            value={-new Date().getTimezoneOffset()}
                        />
                        <input
                            type="hidden"
                            name="version"
                            value={getSepviewVersion()}
                        />
                        {children}
                    </MultipleFormSubmit>
                )}
            </React.Fragment>
        );
    }
}

export default DropdownExport;
