import {Selection} from "d3-selection";

import {EDocumentTypes} from "@/components/project/models";
import {ILocalizedText} from "@translate/models";

export const DEFAULT_TEMPERATURE_DECIMAL = 0;
export const DEFAULT_WAVELENGTH_DECIMAL = 0;
export const DEFAULT_ENERGY_DECIMAL = 1;

export enum EProfileDirection {
    Upright,
    UpsideDown,
}

export const DEFAULT_ACSV: IACsvValue = {
    on: false,
    allowSop: true,
    path: "",
    direction: EProfileDirection.Upright,
    region: "user",
    useTabSeparator: false,
};

export interface IModuleInfo {
    icon: string;
    type: ILocalizedText;
}

interface IExclude {
    id: number;
    type: EDocumentTypes;
}

export interface INameCheckRequest {
    name: string;
    exclude: IExclude;
}

export interface INameCheckResponse {
    name: string;
    isInUse: boolean;
}

export interface IACsvValue {
    on: boolean;
    allowSop: boolean;
    path: string;
    direction: EProfileDirection;
    region: string;
    useTabSeparator: boolean;

    computerName?: string;
    lockedPath?: string;
}

export interface IDragBound {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}

export type IDragHandler = () => Selection<
    HTMLElement,
    string,
    null,
    undefined
>;
