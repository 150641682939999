// those consts are copies of _variables.scss values

export const LUM_GREEN = "#139839"; // primary
export const ERROR_RED = "#ff4238"; // danger
export const WARNING_ORANGE = "#ff851a"; // warning
export const INFO_BLUE = "#75caeb"; // info
export const ROYAL_BLUE = "#4169e1";

export const CARD_PADDING = 6; // card-spacer-y
export const TABLE_PADDING = 4.8; // table-cell-padding-sm
export const BORDER_PADDING = 0.8;
export const TAB_HEIGHT = 44; // .tab
