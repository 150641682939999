import {faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import T, {intl2Str} from "@translate/T";
import SaveButton from "../button-like/SaveButton";
import ValidatedForm from "../button-like/ValidatedForm";
import Notes from "../design/Notes";

export function fallbackTextareaTitle(intl: IntlShape, required?: boolean) {
    if (!required) {
        return undefined;
    }

    return intl2Str(intl, "Please fill out this field.");
}

export interface IInlineNotesProps {
    value: string;
    suffixId: string;

    rows?: number; // number of rows, default to 10
    readonly?: boolean;
    required?: boolean;
    hideButton?: boolean;

    onSave(value: string): boolean | Promise<boolean>; // save event handler. Returns false to indicates saving error
}

interface IInlineNotesState {
    text: string;
    prevValue: string;
    isEditMode: boolean;
}

class InlineNotes extends React.PureComponent<
    IInlineNotesProps,
    IInlineNotesState
> {
    public readonly state: IInlineNotesState = {
        isEditMode: false,
        prevValue: "",
        text: "",
    };

    private readonly saveButton = React.createRef<SaveButton>();

    private get effective() {
        return this.state.text.trim();
    }

    public static getDerivedStateFromProps(
        props: IInlineNotesProps,
        state: IInlineNotesState,
    ): Partial<IInlineNotesState> {
        if (props.value === state.prevValue) {
            return {};
        }

        return {prevValue: props.value, text: props.value};
    }

    @boundMethod
    public showEditor() {
        if (this.props.readonly) {
            return;
        }

        this.setState({isEditMode: true, text: this.state.prevValue});
    }

    @boundMethod
    public setInputElement(input: HTMLTextAreaElement) {
        if (input) {
            input.focus();
        }
    }

    @boundMethod
    public setText(e: React.ChangeEvent<HTMLTextAreaElement>) {
        e.preventDefault();

        this.setState({text: e.target.value});
    }

    @boundMethod
    public async saveChanges() {
        try {
            const saveResult = this.props.onSave(this.effective);
            const hasError = !(await Promise.resolve(saveResult));

            this.setState({isEditMode: hasError});

            return !hasError;
        } catch {
            this.setState({isEditMode: true});

            return false;
        }
    }

    @boundMethod
    public cancel(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({isEditMode: false});
    }

    @boundMethod
    public submitForm() {
        this.saveButton.current?.submitForm();
    }

    public render() {
        return (
            <div>
                {this.renderValue()}
                <IntlContext.Consumer children={this.renderEditor} />
            </div>
        );
    }

    private renderValue() {
        const {hideButton, readonly, suffixId, value} = this.props;
        if (this.state.isEditMode) {
            return null;
        }

        if (readonly) {
            return <Notes value={value} suffixId={suffixId} />;
        }

        return (
            <div className="form-check-inline w-100 m-0">
                <Notes value={value} suffixId={suffixId} withEdit={true} />
                {!hideButton && (
                    <button
                        type="button"
                        className="btn btn-link p-0 border-0 m-0 ml-1"
                        data-testid={"edit-" + suffixId}
                        onClick={this.showEditor}
                    >
                        <FontAwesomeIcon icon={faEdit} fixedWidth={true} />
                    </button>
                )}
            </div>
        );
    }

    @boundMethod
    private renderEditor(intl: IntlShape) {
        const {required, rows, suffixId} = this.props;
        const {isEditMode, text} = this.state;
        if (!isEditMode) {
            return null;
        }

        let id = "inline-" + suffixId + "-input";
        if (required) {
            id += "-trim";
        }

        return (
            <ValidatedForm suffixId="inline-notes" onSubmit={this.submitForm}>
                <textarea
                    ref={this.setInputElement}
                    id={id}
                    data-testid={id}
                    className="form-control"
                    style={{resize: "none"}}
                    required={required}
                    rows={rows ?? 10}
                    title={fallbackTextareaTitle(intl, required)}
                    value={text}
                    onChange={this.setText}
                />

                <div className="btn-group mt-1">
                    <SaveButton
                        ref={this.saveButton}
                        id={"save-" + suffixId}
                        noAnimation={true}
                        onSave={this.saveChanges}
                    />
                    <button
                        type="button"
                        data-testid={"cancel-" + suffixId}
                        id={"cancel-" + suffixId}
                        className="btn btn-secondary"
                        onClick={this.cancel}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Cancel</T>
                    </button>
                </div>
            </ValidatedForm>
        );
    }
}

export default InlineNotes;
