import {isEqual} from "lodash";
import React from "react";

import {EDocumentTypes} from "@/components/project/models";
import http from "@/services/http";
import {INameCheckRequest, INameCheckResponse} from "./models";

import T from "@translate/T";

export interface INameCheckProps {
    apiUrl: string;
    id: number;
    name: string;
    type: EDocumentTypes;

    htmlNativ?: string;
    className?: string;
    showError?: () => void; // will trigger once we get new feedback if name in use
}

interface INameCheckState {
    isUsed?: boolean;
}

class NameChecker extends React.PureComponent<
    INameCheckProps,
    INameCheckState
> {
    public readonly state: INameCheckState = {};

    private controller?: AbortController;

    public componentDidMount() {
        this.retrieve();
    }

    public componentDidUpdate(prevProps: Readonly<INameCheckProps>) {
        if (!isEqual(this.props, prevProps)) {
            this.retrieve();
        }
    }

    public componentWillUnmount() {
        this.controller?.abort();
    }

    public render() {
        const {className, htmlNativ, showError} = this.props;
        if (!this.state.isUsed) {
            return null;
        }

        let _id = "";
        let _className = "form-text text-warning";
        if (showError) {
            _id = "text-danger ";
            _className = _className.replace("text-warning", "text-danger");
        }

        if (className) {
            _className += " " + className;
        }

        return React.createElement(
            htmlNativ ?? "div",
            {className: _className, id: _id + "name-in-use"},
            <T>Warning: The specified name has already been used.</T>,
        );
    }

    private async retrieve() {
        this.controller?.abort();

        const {apiUrl, id, name, type, showError} = this.props;
        if (!name) {
            this.setState({isUsed: false}, () => showError?.());
            return;
        }

        this.controller = new AbortController();
        const json: INameCheckRequest = {
            exclude: {id, type},
            name,
        };

        try {
            const response = await http
                .post(apiUrl, {json, signal: this.controller.signal})
                .json<INameCheckResponse>();

            this.setState({isUsed: response.isInUse}, () => showError?.());
        } catch {
            this.setState({isUsed: false}, () => showError?.());
        }
    }
}

export default NameChecker;
