import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {intl2Num, intl2Str} from "@translate/T";
import {TIMES_LATEX} from "../math/models";
import {FACTOR} from "./models";

import MathBlock from "../math/MathBlock";

interface IFormulaProps {
    intl: IntlShape;
}

class Formula extends React.PureComponent<IFormulaProps> {
    private get formula() {
        const {intl} = this.props;

        const rca = intl2Str(intl, "RCA");
        const rpm = intl2Str(intl, "RPM"); // ToDo: change to "N"
        const r = intl2Str(intl, "r");
        const factor = intl2Num(intl, FACTOR);
        return `\\mathit{${rca}} = {\\left( \\dfrac{\\mathit{${rpm}}}{1000} \\right)}^2 ${TIMES_LATEX} ${r} ${TIMES_LATEX} ${factor}`;
    }

    public render() {
        return <MathBlock latex={this.formula} />;
    }
}

export default injectIntl(Formula);
