import {faSlidersH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlShape} from "react-intl";

import networkDevices from "@/services/network-devices";
import {ISelectValue} from "@toolbox/button-like/models";
import {IDeviceClasses, ILicensedDevice} from "@toolbox/models";
import {ILocalizedText} from "@translate/models";
import {ETimeRange, IMatchCategory, ISearchBoxValue} from "./models";

import PageSize from "@toolbox/building-blocks/PageSize";
import InlineSelect from "@toolbox/button-like/InlineSelect";
import SearchInput from "@toolbox/nativ-inputs/SearchInput";
import T, {intl2Str} from "@translate/T";
import Suggestion from "./Suggestion";

export interface ISearchBoxProps {
    value: ISearchBoxValue;

    suggestions?: string[];
    tags?: IMatchCategory[];
    types?: IMatchCategory[];
    wavelengths?: IMatchCategory[];
    devices?: IMatchCategory[];
    comments?: IMatchCategory[];
    emptyResult: boolean;

    onSearch(value: ISearchBoxValue): void;
}

interface ISearchBoxState {
    showOptions?: boolean;
}

class SearchBox extends React.PureComponent<ISearchBoxProps, ISearchBoxState> {
    public readonly state: ISearchBoxState = {
        showOptions:
            this.props.value.pageSize !== 20 ||
            this.props.value.dateRange !== ETimeRange.Any,
    };

    @boundMethod
    public setBadge(badge: string) {
        const {value} = this.props;
        const query = value.query === "" ? badge : value.query + " " + badge;
        this.setQuery(query);
    }

    @boundMethod
    public setQuery(query: string) {
        const {value, onSearch} = this.props;
        onSearch({...value, query});
    }

    @boundMethod
    public setTimeRange(dateRange: ETimeRange) {
        const {value, onSearch} = this.props;
        onSearch({...value, dateRange});
    }

    @boundMethod
    public setPageSize(pageSize: number | "all") {
        const {value, onSearch} = this.props;

        onSearch({...value, pageSize});
    }

    @boundMethod
    public toggleOptions(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showOptions: !this.state.showOptions});
    }

    public render() {
        const {emptyResult, value} = this.props;

        return (
            <React.Fragment>
                <SearchInput query={value.query} onSearch={this.setQuery}>
                    {this.renderOptionsButton()}
                </SearchInput>

                {!emptyResult && (
                    <React.Fragment>
                        {this.renderOptions()}
                        {this.renderSuggestions()}
                        {this.renderTags()}
                        {this.renderTypes()}
                        {this.renderWavelengths()}
                        {this.renderDevices()}
                        {this.renderComments()}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    private renderOptionsButton() {
        let className = "btn btn-secondary";
        if (this.state.showOptions) {
            className += " active";
        }

        return (
            <button
                type="button"
                id="btn-time"
                data-testid="search-options"
                className={className}
                onClick={this.toggleOptions}
            >
                <FontAwesomeIcon icon={faSlidersH} fixedWidth={true} />
            </button>
        );
    }

    private renderOptions() {
        const {dateRange, pageSize} = this.props.value;

        if (!this.state.showOptions) {
            return null;
        }

        return (
            <div className="form-row mb-2">
                <InlineSelect<ETimeRange>
                    idSuffix="modified-range"
                    classNameLabel="col-sm-3 d-flex align-items-center"
                    classNameDiv="col-sm-3"
                    label={<T>Last modified</T>}
                    selected={dateRange}
                    onSelected={this.setTimeRange}
                    convert={this.convert}
                    values={Object.values(ETimeRange)}
                />

                <PageSize
                    label={<T>Items per page</T>}
                    classNameLabel="col-sm-3 d-flex align-items-center"
                    classNameDiv="col-sm-3"
                    value={pageSize}
                    onChange={this.setPageSize}
                    allowAll={true}
                />
            </div>
        );
    }

    private renderSuggestions() {
        const {suggestions} = this.props;
        if (!suggestions?.length) {
            return null;
        }

        const links = suggestions.map((x, i) => (
            <Suggestion key={i} value={x} onClick={this.setQuery} />
        ));

        return (
            <div>
                <small>
                    <T>Did you mean:</T> {links}
                </small>
            </div>
        );
    }

    private renderTags() {
        const {tags} = this.props;
        if (!tags?.length) {
            return null;
        }

        const links = tags.map((x, i) => (
            <Suggestion
                key={i}
                value={x.key}
                prefix="tag:"
                count={x.count}
                onClick={this.setBadge}
            />
        ));

        return (
            <div>
                <small>
                    <T>Tags:</T> {links}
                </small>
            </div>
        );
    }

    private renderTypes() {
        const {types} = this.props;
        if (!types?.length) {
            return null;
        }

        const links = types.map((x, i) => (
            <Suggestion
                key={i}
                value={x.key}
                prefix="type:"
                count={x.count}
                displayValue={this.renderDisplayTypes}
                onClick={this.setBadge}
            />
        ));

        return (
            <div>
                <small>
                    <T>Document types:</T> {links}
                </small>
            </div>
        );
    }

    private renderWavelengths() {
        const {wavelengths} = this.props;
        if (!wavelengths?.length) {
            return null;
        }

        const links = wavelengths.map((x, i) => (
            <Suggestion
                key={i}
                value={x.key}
                prefix="wavelength:"
                count={x.count}
                onClick={this.setBadge}
            />
        ));

        return (
            <div>
                <small>
                    <T>Wavelengths:</T> {links}
                </small>
            </div>
        );
    }

    private renderDevices() {
        const {devices} = this.props;
        if (!devices?.length) {
            return null;
        }

        const links = devices.map((x, i) => (
            <Suggestion
                key={i}
                value={x.key}
                prefix="device:"
                count={x.count}
                displayValue={this.renderDisplayDevices}
                onClick={this.setBadge}
            />
        ));

        return (
            <div>
                <small>
                    <T>Devices:</T> {links}
                </small>
            </div>
        );
    }

    private renderComments() {
        const {comments} = this.props;
        if (!comments?.length) {
            return null;
        }

        const links = comments.map((x, i) => (
            <Suggestion
                key={i}
                value={x.key}
                prefix="comment:"
                count={x.count}
                onClick={this.setBadge}
            />
        ));

        return (
            <div>
                <small>
                    <T>Comments:</T> {links}
                </small>
            </div>
        );
    }

    private renderDisplayTypes(intl: IntlShape, value: string) {
        switch (value) {
            case "analysis":
                return intl2Str(intl, "Analysis");

            case "measurement":
                return intl2Str(intl, "Measurement");

            case "calibration":
                return intl2Str(intl, "Calibration");

            case "sop":
                return intl2Str(intl, "SOP");

            case "report":
                return intl2Str(intl, "Report");

            default:
                return value;
        }
    }

    private renderDisplayDevices(intl: IntlShape, value: string) {
        const parts = value.split("_");
        if (parts.length < 3) {
            return value;
        }

        const _fabrication = parts.pop()!;
        const _series = parts.pop()!;
        const device: ILicensedDevice = {
            ...networkDevices.getDeviceId(_series + "-" + _fabrication),
            deviceClass: parts.join(" ") as IDeviceClasses,
        };

        return networkDevices.getDeviceName(intl, device);
    }

    private convert(value: ETimeRange): ISelectValue {
        let name: ILocalizedText = (intl) => intl2Str(intl, "None");

        switch (value) {
            case ETimeRange.Any:
                name = (intl) => intl2Str(intl, "Any time");
                break;

            case ETimeRange.PastHour:
                name = (intl) => intl2Str(intl, "Past hour");
                break;

            case ETimeRange.PastDay:
                name = (intl) => intl2Str(intl, "Past 24 hours");
                break;

            case ETimeRange.PastWeek:
                name = (intl) => intl2Str(intl, "Past week");
                break;

            case ETimeRange.PastMonth:
                name = (intl) => intl2Str(intl, "Past month");
                break;

            case ETimeRange.PastYear:
                name = (intl) => intl2Str(intl, "Past year");
                break;
        }

        return {id: value.toString(), name};
    }
}

export default SearchBox;
