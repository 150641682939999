import {INumberOrNaN} from "./models";

export function parseNaNText(value: INumberOrNaN | null | undefined): number {
    if (typeof value === "number") {
        return value;
    }

    if (value === "-Infinity") {
        return -Infinity;
    }

    if (value === "Infinity") {
        return Infinity;
    }

    return NaN;
}

export function parseNaNtoText(value: number): INumberOrNaN {
    if (isNaN(value)) {
        return "NaN";
    }

    if (value === -Infinity) {
        return "-Infinity";
    }

    if (value === Infinity) {
        return "Infinity";
    }

    return value;
}
