import {KatexOptions} from "katex";

export const TIMES_LATEX = "⋅";
export const KATEX_OPTIONS: KatexOptions = {
    // output: "html",
};

export interface MathValues {
    temperature?: number;
    wavelength?: number;
    concentration?: number;
}
