import {faInbox} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link} from "react-router-dom";

import http from "@/services/http";
import session from "@/services/session";
import signal from "@/services/signal";
import {IQueuedSopModel} from "./models";

import Modal from "@toolbox/modals/Modal";
import T from "@translate/T";
import ManageSopQueue from "./ManageSopQueue";
import SopInfo from "./SopInfo";

interface IQueueState {
    authenticated: boolean;
    showManager?: boolean;
    items: IQueuedSopModel[];
}

class Queue extends React.PureComponent<{}, IQueueState> {
    public readonly state: IQueueState = {
        authenticated: !!session.token,
        items: [],
    };

    private unsubscribe?: () => void;
    private readonly ref = React.createRef<Modal>();

    @boundMethod
    public showManager(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showManager: true});
    }

    @boundMethod
    public onModalClosed() {
        this.setState({showManager: false});
    }

    public async componentDidMount() {
        const unSignal = signal.register({sopQueueChanged: this.refreshQueue});
        const unSession = session.subscribe({
            authenticated: () =>
                this.setState({authenticated: true}, this.refreshQueue),
            expired: () => this.setState({authenticated: false}),
        });

        if (session.hasSession) {
            await this.refreshQueue();
        }

        this.unsubscribe = () => {
            unSignal?.();
            unSession();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {authenticated, items, showManager} = this.state;
        const count = items.length;
        if (!authenticated || (!count && !showManager)) {
            return null;
        }

        return (
            <React.Fragment>
                <li className="nav-item dropdown">
                    <Link
                        id="queued-sops"
                        className="nav-link dropdown-toggle"
                        aria-expanded="false"
                        aria-haspopup="true"
                        data-toggle="dropdown"
                        role="button"
                        to=""
                    >
                        <FontAwesomeIcon icon={faInbox} fixedWidth={true} />
                        <span className="badge badge-secondary">{count}</span>
                    </Link>

                    <div
                        aria-labelledby="help-menu"
                        className="dropdown-menu dropdown-menu-right"
                    >
                        {this.renderQueuedItems()}

                        <div className="dropdown-divider" />

                        <button
                            type="button"
                            id="manage-queued-sops"
                            className="dropdown-item"
                            onClick={this.showManager}
                        >
                            <T>Manage</T>
                        </button>
                    </div>
                </li>
                {this.renderManager()}
            </React.Fragment>
        );
    }

    private renderQueuedItems() {
        return this.state.items.map((item) => (
            <Link key={item.id} to={SopInfo.getQueueUrl(item)}>
                <button type="button" className="dropdown-item">
                    {item.sopName}
                </button>
            </Link>
        ));
    }

    private renderManager() {
        const {items, showManager} = this.state;
        if (!showManager) {
            return null;
        }

        return (
            <Modal
                ref={this.ref}
                header={<T>SOP queue</T>}
                notCentered={true}
                size="xl"
                afterClose={this.onModalClosed}
            >
                <ManageSopQueue items={items} />
            </Modal>
        );
    }

    @boundMethod
    private async refreshQueue() {
        try {
            const items = await http
                .get("/api/centrifuge/sop/queue")
                .json<IQueuedSopModel[]>();

            this.setState(
                {items},
                !items.length ? this.ref.current?.triggerClose : () => null,
            );
        } catch {
            this.setState({items: []}, this.ref.current?.triggerClose);
        }
    }
}

export default Queue;
