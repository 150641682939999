import "./InlineSelect.module.scss";

import {boundMethod} from "autobind-decorator";
import {max} from "d3-array";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";
import {HtmlPortalNode, InPortal} from "react-reverse-portal";
import {Link} from "react-router-dom";

import {ILocalizedText} from "@translate/models";
import {ISelectValue} from "./models";

import T, {intl2Num} from "@translate/T";

interface IInlineSelectProps<TValue> {
    values: TValue[];
    idSuffix: string;
    selected: TValue;
    convert?(value: TValue): ISelectValue;

    autoWidth?: boolean; // do not use this lightly !!
    portal?: HtmlPortalNode; // fancy portal for trapped tables

    classNameDiv?: string;
    classNameInput?: string;
    classNameLabel?: string;
    classNameOption?: string;
    dataTestId?: string;
    disabled?: boolean;
    disableOnEmpty?: boolean;
    disableSpecialId?: string;
    dropup?: boolean;
    dynamicDropUpDown?: boolean;
    goLinkInstead?: boolean;
    style?: React.CSSProperties;
    useSimpleNumber?: boolean;

    invalid?: boolean;
    invalidWarning?: boolean;

    label?: JSX.Element | ILocalizedText;
    title?: ILocalizedText;
    titles?: ILocalizedText[]; // for the options

    otherSelectDisplay?(intl: IntlShape, value: TValue): string;
    otherOptionDisplay?(intl: IntlShape, value: TValue): JSX.Element | string;

    onSelected(value: TValue, idSuffix: string): void;
}

interface IInlineSelectState {
    search: string;
    upwards: boolean;
}

class InlineSelect<TValue extends string | number> extends React.PureComponent<
    IInlineSelectProps<TValue>,
    IInlineSelectState
> {
    public readonly state: IInlineSelectState = {
        search: "",
        upwards: !!this.props.dropup,
    };

    private readonly dropdown = React.createRef<HTMLDivElement>();
    private readonly options = React.createRef<HTMLDivElement>();
    private readonly select = React.createRef<
        HTMLInputElement & HTMLAnchorElement
    >();
    private readonly onScroll = () => {
        const dropdown = this.dropdown.current;
        if (!dropdown) {
            return;
        }

        const rect = dropdown.getBoundingClientRect();
        const middle = rect.top + rect.height / 2;
        const upwards = middle > window.innerHeight / 2;
        this.setState({upwards});
    };

    private get id() {
        return "select-" + this.props.idSuffix;
    }

    public componentDidMount() {
        if (this.props.dynamicDropUpDown) {
            document.addEventListener("scroll", this.onScroll, {passive: true});
            this.onScroll();
        }
    }

    public componentWillUnmount() {
        if (this.props.dynamicDropUpDown) {
            document.removeEventListener("scroll", this.onScroll);
        }
    }

    @boundMethod
    public onDivClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const id = (e.target as HTMLElement).id;
        if (id.startsWith("div")) {
            this.select.current?.click();
        }
    }

    @boundMethod
    public onSelected(value: TValue) {
        const {onSelected, idSuffix} = this.props;

        this.hideOptions();
        this.setState({search: ""}, () => onSelected(value, idSuffix));
    }

    @boundMethod
    public updateSearchText(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({search: e.target.value});
    }

    @boundMethod
    public clearSearchText(e: React.FocusEvent) {
        const target = e.relatedTarget as Element | null;
        if (target && "BUTTON" === target.tagName) {
            const dropdown = target.closest(".dropdown");
            if (dropdown === this.dropdown.current) {
                return;
            }
        }

        this.hideOptions();
        this.setState({search: ""});
    }

    @boundMethod
    public handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.key) {
            case "Escape":
                e.preventDefault();

                this.hideOptions();
                this.setState({search: ""});
                break;

            case "Enter":
                e.preventDefault();

                const firstChild = this.options.current?.firstChild as
                    | HTMLElement
                    | null
                    | undefined;
                firstChild?.click();

                break;
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderLabel()}
                {this.renderDiv()}
            </React.Fragment>
        );
    }

    private renderLabel() {
        const {classNameLabel, label} = this.props;

        if (!label) {
            return null;
        }

        return (
            <label htmlFor={this.id} className={classNameLabel ?? "mb-1"}>
                {typeof label === "function" ? (
                    <IntlContext.Consumer children={label} />
                ) : (
                    label
                )}
            </label>
        );
    }

    private renderDiv() {
        const {classNameDiv} = this.props;

        return (
            <div className={classNameDiv}>
                <IntlContext.Consumer children={this.renderSelect} />
            </div>
        );
    }

    @boundMethod
    private renderSelect(intl: IntlShape) {
        const {upwards} = this.state;
        const menuId = this.id + "-menu";

        return (
            <div
                ref={this.dropdown}
                id={"div-" + this.id}
                className={`drop${upwards ? "up" : "down"} pointer-cursor`}
                styleName="mimic-select"
                onClick={this.onDivClick}
            >
                {this.renderInput(intl, menuId)}
                {this.renderProtalMenu(intl, menuId)}
            </div>
        );
    }

    private renderInput(intl: IntlShape, menuId: string) {
        const {
            autoWidth,
            classNameInput,
            dataTestId,
            disabled,
            goLinkInstead,
            invalid,
            invalidWarning,
            otherSelectDisplay,
            portal,
            selected,
            style,
            title,
            useSimpleNumber,
            otherOptionDisplay,
        } = this.props;
        const {search} = this.state;

        let className =
            "form-control from-control-inline inline-select-dropdown pointer-cursor";
        if (classNameInput) {
            className += " " + classNameInput;
        }

        if (useSimpleNumber) {
            className = className.replace(
                "form-control from-control-inline",
                "simple-number",
            );
        }

        if (invalid) {
            className += " is-invalid";
        } else if (invalidWarning) {
            className += " is-invalid-warning";
        }

        let _style: React.CSSProperties = style ?? {};
        if (autoWidth) {
            _style = {..._style, minWidth: this.getSelectionWidth(intl)};
        }

        if (goLinkInstead) {
            return (
                <Link
                    ref={this.select}
                    id={this.id}
                    className={className}
                    styleName="placeholder-input"
                    style={_style}
                    aria-expanded="false"
                    aria-haspopup="true"
                    data-target={!!portal ? "#" + menuId : undefined}
                    data-testid={dataTestId ?? this.id}
                    data-toggle="dropdown"
                    role="button"
                    title={title?.(intl)}
                    to=""
                >
                    {otherOptionDisplay?.(intl, selected) ??
                        this.convert(selected).name(intl)}
                </Link>
            );
        }

        return (
            <input
                ref={this.select}
                type="text"
                id={this.id}
                className={className}
                style={_style}
                styleName="placeholder-input"
                aria-expanded="false"
                aria-haspopup="true"
                autoComplete="off"
                // data-display="static" // not sure what this does
                data-target={!!portal ? "#" + menuId : undefined}
                data-testid={dataTestId ?? this.id}
                data-toggle="dropdown"
                disabled={disabled}
                placeholder={
                    otherSelectDisplay?.(intl, selected) ??
                    this.convert(selected).name(intl)
                }
                title={title?.(intl)}
                value={search}
                onBlur={this.clearSearchText}
                onKeyDown={this.handleKeyDown}
                onChange={this.updateSearchText}
            />
        );
    }

    // never start an Id with a number, if you use Portal.
    private renderProtalMenu(intl: IntlShape, menuId: string) {
        const {portal} = this.props;

        if (!menuId || !portal) {
            return this.renderDropdown(intl);
        }

        return (
            <InPortal node={portal}>
                <div id={menuId}>{this.renderDropdown(intl)}</div>
            </InPortal>
        );
    }

    private renderDropdown(intl: IntlShape) {
        const {portal, values} = this.props;
        const {search} = this.state;

        const filtered = values.filter((value) =>
            this.search(intl, this.convert(value), search),
        );

        let _style: React.CSSProperties = {};
        if (portal) {
            _style = {..._style, width: this.getSelectionWidth(intl)};
        }

        return (
            <div
                ref={this.options}
                className="dropdown-menu dropdown-menu-right div-overflow-y"
                style={_style}
                styleName="scrollable"
                aria-labelledby={this.id}
            >
                {!filtered.length && (
                    <div className="dropdown-item text-muted">
                        <T>No match found</T>
                    </div>
                )}
                {filtered.map((value, i) => this.renderOption(intl, value, i))}
            </div>
        );
    }

    private renderOption(intl: IntlShape, value: TValue, i: number) {
        const {selected, otherOptionDisplay, titles, classNameOption} =
            this.props;
        const {id, name} = this.convert(value);
        const onClick = () => this.onSelected(value);

        let className = "dropdown-item";
        if (classNameOption) {
            className += " " + classNameOption;
        }

        if (id === this.convert(selected).id) {
            className += " active";
        }

        // empty string is identifier for dropdown-divider
        if (value === "dropdown-divider") {
            return (
                <div
                    key={id}
                    id={"option-" + this.id + "-" + id}
                    data-testid={"option-" + this.id + "-" + id}
                    className="dropdown-divider"
                />
            );
        }

        return (
            <button
                type="button"
                key={id}
                id={"option-" + this.id + "-" + id}
                data-testid={"option-" + this.id + "-" + id}
                className={className}
                styleName={this.isDisabled(id) ? "color-option" : undefined}
                disabled={this.isDisabled(id)}
                title={titles?.[i](intl)}
                value={id}
                onClick={onClick}
            >
                {otherOptionDisplay?.(intl, value) ?? name(intl)}
            </button>
        );
    }

    private convert(id: TValue): ISelectValue {
        const {convert} = this.props;

        const value = convert?.(id) ?? {
            id: id.toString(),
            name: (intl) => intl2Num(intl, id),
        };

        // e2e tests cannot work with "."
        value.id = value.id.replace(".", "");

        return value;
    }

    // disable option if key is empty and desired
    private isDisabled(id: string) {
        const {disableOnEmpty, disableSpecialId} = this.props;
        return (disableOnEmpty && id === "") || disableSpecialId === id;
    }

    private search(intl: IntlShape, value: ISelectValue, search: string) {
        const query = search.trim().toUpperCase();

        return (
            [value.name(intl), value.moresearchhelp?.(intl) ?? ""]
                .join(" ")
                .toUpperCase()
                .indexOf(query) >= 0
        );
    }

    private hideOptions() {
        if (!this.props.dynamicDropUpDown) {
            return; // only need to manualy hide, if we have this random dynamic option
        }

        const options = this.options.current;
        if (options) {
            const className = options.className;
            if (className?.endsWith(" show")) {
                options.className = className.replace(" show", "");
            }
        }
    }

    private getSelectionWidth(intl: IntlShape) {
        const {otherSelectDisplay, values} = this.props;
        const converting = (converter: TValue) =>
            otherSelectDisplay?.(intl, converter) ??
            this.convert(converter).name(intl);

        // look at all options, since otherwise those might be cut of
        const len = max(values, (value) => converting(value).length);
        return len + "ch";
    }
}

export default InlineSelect;
