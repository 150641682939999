import "./RolesAssignment.module.scss";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {EDocumentTypes} from "@/components/project/models";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import {ILocalizedText} from "@translate/models";

import UserLink from "@toolbox/building-blocks/UserLink";
import T, {intl2Str} from "@translate/T";
import Role from "./Role";

export interface IRolesAssignmentProps {
    idSuffix: string;
    role: ERoles;
    inheritedRole?: ERoles;

    disabled?: boolean;
    header?: string;
    clearText?: JSX.Element;
    type: EDocumentTypes.User | EDocumentTypes.Group;

    globals?: boolean; // sets to true to show global roles (admin, global admin)

    onChange(role: ERoles, clear?: string): void;
}

class RolesAssignment extends React.PureComponent<IRolesAssignmentProps> {
    private get common() {
        const {disabled, idSuffix, onChange, role, inheritedRole} = this.props;

        return {disabled, idSuffix, onChange, role, inheritedRole};
    }

    @boundMethod
    public clear() {
        const {onChange, role} = this.props;
        if (!session.canEditThisUser(role)) {
            return;
        }

        onChange(ERoles.None, "clear");
    }

    public render() {
        const {clearText, disabled} = this.props;

        return (
            <React.Fragment>
                {this.renderHeader()}

                <Role {...this.common} flag={ERoles.Viewer}>
                    <T>Viewer</T>
                </Role>
                <Role {...this.common} flag={ERoles.Recorder}>
                    <T>Recorder</T>
                </Role>
                <Role {...this.common} flag={ERoles.Editor}>
                    <T>Editor</T>
                </Role>
                <Role {...this.common} flag={ERoles.Manager}>
                    <T>Manager</T>
                </Role>

                {this.renderGlobalRoles()}

                <td styleName="clearStyle">
                    <button
                        type="button"
                        disabled={disabled}
                        className="btn btn-sm btn-secondary"
                        onClick={this.clear}
                    >
                        {clearText ?? <T>Clear</T>}
                    </button>
                </td>
            </React.Fragment>
        );
    }

    private renderHeader() {
        const {header, type} = this.props;
        if (!header) {
            return null;
        }

        return (
            <th scope="row">
                <UserLink
                    username={header}
                    displayName={
                        type === EDocumentTypes.User ? undefined : header
                    }
                    documentType={type}
                />
            </th>
        );
    }

    private renderGlobalRoles() {
        const {disabled, globals} = this.props;
        if (!globals) {
            return null;
        }

        const title: ILocalizedText | undefined = !session.hasRole(
            ERoles.GlobalAdministrator,
        )
            ? (intl) => intl2Str(intl, "Insufficient rights!")
            : undefined;

        return (
            <React.Fragment>
                <Role {...this.common} flag={ERoles.Administrator}>
                    <T>Administrator</T>
                </Role>
                <Role
                    {...this.common}
                    disabled={title !== undefined || disabled}
                    flag={ERoles.GlobalAdministrator}
                    title={title}
                >
                    <T>Global administrator</T>
                </Role>
            </React.Fragment>
        );
    }
}

export default RolesAssignment;
