import {boundMethod} from "autobind-decorator";
// tslint:disable-next-line:no-implicit-dependencies
import {PrimitiveType} from "intl-messageformat";
import React from "react";
import {FormatNumberOptions, IntlContext, IntlShape} from "react-intl";

import {intlLocales} from "@/services/intl-locale";
import {ILanguageId, IRegionId} from "@/services/models";

import {getTranslator} from "./Provider";

export function intl2Str(
    intl: IntlShape,
    values: string | string[],
    options?: Record<string, PrimitiveType>,
    seporator: string = " ",
): string {
    return (!Array.isArray(values) ? [values] : values)
        .map((x) => intl.formatMessage({defaultMessage: x, id: x}, options))
        .join(seporator);
}

export function intl2Num(
    intl: IntlShape,
    values: string | number | (number | string)[],
    decimals?: number | undefined,
    options: FormatNumberOptions = {},
    seperator: string = " ",
): string {
    const fullArray = !Array.isArray(values) ? [values] : values;
    const formater = getNumberFormater(intl, fullArray, decimals, options);

    return fullArray
        .map((x) => (typeof x !== "string" ? formater(x) : x))
        .join(seperator);
}

export function getNumberFormater(
    intl: IntlShape,
    fullArray: (number | string)[],
    decimals?: number | undefined,
    options: FormatNumberOptions = {},
) {
    if (decimals !== undefined) {
        options.maximumFractionDigits = decimals;
        options.minimumFractionDigits = decimals;
    }

    const forceScientific = options.notation === "scientific";
    const noPrinting = options.useGrouping !== false;
    const noOtherNotation = options.notation === undefined;
    if (
        forceScientific ||
        (noPrinting && noOtherNotation && isScientificNum(fullArray))
    ) {
        // "useGrouping" as an indicator that number shall be raw
        options.notation = "scientific";
        options.maximumFractionDigits = 3;
        options.minimumFractionDigits = undefined;
        options.maximumSignificantDigits = undefined;
        options.minimumSignificantDigits = undefined;
    }

    if (
        options.maximumFractionDigits === undefined &&
        options.maximumSignificantDigits === undefined &&
        options.minimumFractionDigits === undefined &&
        options.minimumSignificantDigits === undefined
    ) {
        // fyi: it seems that intl will round after 3 digits, if nothing is provided.
        options.maximumFractionDigits = 16;
    }

    const {region} = intl2LocaleParts(intl.locale);
    if (region !== "") {
        intl = getTranslator("en", region);
    }

    const formater = intl.formatters.getNumberFormat(intl.locale, options);

    return (x: number) => customNumIntl(formater.format(x));
}

export function parseNumber(text: string): number {
    text = text.trim().replace(/,/g, ".");
    return Number(text);
}

export function isScientificNum(tmp: (string | number)[]): boolean {
    return !!tmp.find((x) => typeof x === "number" && Math.abs(x) >= 1000000);
}

export function getDecimal(intl: IntlShape) {
    return intl2Num(intl, 0.1).replace(/[01]/g, "");
}

export function intl2LocaleParts(locale: string): {
    language: ILanguageId;
    region: IRegionId | "";
} {
    const parts = locale.split("-");
    const last = parts[parts.length - 1];

    let region: IRegionId | "" = "";
    if (intlLocales.find((x) => x.id === last)) {
        region = parts.pop() as IRegionId;
    }

    const language = parts.join("-") as ILanguageId;

    return {language, region};
}

function customNumIntl(value: string): string {
    if (!value.replace(/-0[,.]*[0]*/, "")) {
        value = value.replace("-", "");
    }

    if (value.includes("E-")) {
        value = value.replace("E", "e");
    }

    if (value.includes("E")) {
        value = value.replace("E", "e+");
    }

    return value;
}

interface ITProps extends Record<string, PrimitiveType | React.ReactElement> {
    context?: string; // props used only using text extraction
    children: string; // same as `id` and `defaultMessage` for `FormattedMMesasage`
}

class T extends React.PureComponent<ITProps> {
    @boundMethod
    public renderContent(intl: IntlShape) {
        const {children, ...others} = this.props;
        let nodes = intl.formatMessage(
            {id: children, defaultMessage: children},
            others,
        ) as string | React.ReactNodeArray;
        if (!Array.isArray(nodes)) {
            nodes = [nodes];
        }

        // suppress React warning about unique keys
        return React.createElement(React.Fragment, null, ...nodes);
    }

    public render() {
        return <IntlContext.Consumer children={this.renderContent} />;
    }
}

export default T;
