import {faSave, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link, Redirect} from "react-router-dom";

import display from "@/services/display-name";
import http from "@/services/http";
import session from "@/services/session";
import {IUpdateRequest, IUserDetails} from "../account/models";

import ValidatedForm from "@toolbox/button-like/ValidatedForm";
import T from "@translate/T";
import PasswordPanel from "./PasswordPanel";

interface IAccountSettingsProps {
    user: string;
    userDetail: IUserDetails;

    redirectUrl: string;
    usersUrl?: string;

    onUserChance(user: Partial<IUserDetails>): void;
}

interface IAccountSettingsState {
    showErrors: boolean;
    licenseError: boolean;
    forbidden: boolean;
    invalid: boolean;

    newPassword: string;

    isSaved: boolean;
}

class AccountSettings extends React.PureComponent<
    IAccountSettingsProps,
    IAccountSettingsState
> {
    public readonly state: IAccountSettingsState = {
        licenseError: false,
        forbidden: false,
        invalid: false,
        newPassword: "",
        showErrors: false,

        isSaved: false,
    };

    @boundMethod
    public onDisplayNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.props.onUserChance({displayName: e.target.value});
    }

    @boundMethod
    public setNewPassword(newPassword: string) {
        this.setState({newPassword});
    }

    @boundMethod
    public async saveChanges() {
        const {user, userDetail} = this.props;
        const {newPassword} = this.state;
        const {displayName, isActive, individualRoles, source} = userDetail;

        const json: IUpdateRequest = {
            displayName: displayName.trim(),
            isActive,
            roles: individualRoles,
            source,
        };

        if (newPassword) {
            json.password = newPassword;
        }

        try {
            const response = await http.post("/api/users/" + user, {json});
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            display.delete(user);
            await display.getUserDetails(user);
            this.setState({isSaved: true});
        } catch (error) {
            if (error instanceof http.HTTPError) {
                if (error.response.status === 400) {
                    this.setState({invalid: true});
                }

                if (error.response.status === 403) {
                    this.setState({forbidden: true});
                }

                const msg = await error.response.json();
                if (msg.isLicenseError) {
                    this.setState({licenseError: true});
                }
            }
        }
    }

    public render() {
        const {children, redirectUrl, userDetail} = this.props;
        const {isSaved} = this.state;

        if (isSaved) {
            return <Redirect to={redirectUrl} push={true} />;
        }

        return (
            <React.Fragment>
                {this.renderWarnings()}

                <ValidatedForm suffixId="user-edit" onSubmit={this.saveChanges}>
                    {this.renderName(userDetail)}
                    {this.renderPassword(userDetail)}
                    {children}
                    {this.renderButtons()}
                </ValidatedForm>
            </React.Fragment>
        );
    }

    private renderWarnings() {
        const {forbidden, licenseError, invalid} = this.state;
        if (!forbidden && !licenseError && !invalid) {
            return null;
        }

        return (
            <div className="alert alert-danger fade show" role="alert">
                {this.renderLicenseError()}
                {this.renderForbidden()}
                {this.renderInvalid()}
            </div>
        );
    }

    private renderLicenseError() {
        if (!this.state.licenseError) {
            return null;
        }

        return (
            <div>
                <strong className="mr-1">
                    <T>Maximum number of active user reached!</T>
                </strong>
                <T>You cannot activate additional users.</T>
            </div>
        );
    }

    private renderForbidden() {
        if (!this.state.forbidden) {
            return null;
        }

        return (
            <div>
                <strong className="mr-1">
                    <T>Permission denied!</T>
                </strong>
                <T>
                    Change to global administrator requires global administrator
                    permission.
                </T>
            </div>
        );
    }

    private renderInvalid() {
        if (!this.state.invalid) {
            return null;
        }

        return (
            <div>
                <strong className="mr-1">
                    <T>Bad display name!</T>
                </strong>
                <T>Please just use ASCII symbols and not only spaces.</T>
            </div>
        );
    }

    private renderName(userDetail: IUserDetails) {
        return (
            <div className="mb-2">
                <label className="mb-1" htmlFor="display-trim">
                    <T>Display name</T>
                </label>
                <input
                    type="text"
                    id="display-trim"
                    autoComplete="name"
                    className="form-control"
                    required={true}
                    value={userDetail.displayName}
                    onChange={this.onDisplayNameChanged}
                />
                <div className="invalid-feedback">
                    <T>Display name is required!</T>
                </div>
            </div>
        );
    }

    private renderPassword(userDetail: IUserDetails) {
        return (
            <PasswordPanel
                canChange={session.canEditThisUser(userDetail.roles)}
                password={this.state.newPassword}
                onChange={this.setNewPassword}
            />
        );
    }

    private renderButtons() {
        const {user, usersUrl, redirectUrl} = this.props;

        return (
            <div className="d-flex">
                {!!usersUrl && (
                    <Link to={usersUrl + "/delete/" + encodeURIComponent(user)}>
                        <button
                            type="button"
                            id="redirect"
                            className="btn btn-danger"
                        >
                            <FontAwesomeIcon
                                fixedWidth={true}
                                icon={faTrash}
                                className="mr-1"
                            />
                            <T>Delete</T>
                        </button>
                    </Link>
                )}

                <div className="ml-auto">
                    <button type="submit" className="btn btn-primary mr-1">
                        <FontAwesomeIcon
                            icon={faSave}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Update</T>
                    </button>

                    <Link to={redirectUrl}>
                        <button type="button" className="btn btn-secondary">
                            <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth={true}
                                className="mr-1"
                            />
                            <T>Cancel</T>
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default AccountSettings;
