import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";
import {Link} from "react-router-dom";

export interface ISuggestProps {
    value: string;
    count?: number;
    prefix?: string;

    displayValue?(intl: IntlShape, value: string): string;
    onClick(query: string): void;
}

class Suggestion extends React.PureComponent<ISuggestProps> {
    @boundMethod
    public onClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onClick, prefix, value} = this.props;
        const query = (prefix ?? "") + value;
        onClick(query);
    }

    public render() {
        return (
            <Link className="ml-1" to="" onClick={this.onClick}>
                <IntlContext.Consumer children={this.renderText} />
            </Link>
        );
    }

    @boundMethod
    private renderText(intl: IntlShape) {
        const {count, value, displayValue} = this.props;
        if (typeof count !== "number") {
            return value;
        }

        return `${displayValue?.(intl, value) ?? value} (${count})`;
    }
}

export default Suggestion;
