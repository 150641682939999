import {boundMethod} from "autobind-decorator";
import {isEqual} from "lodash";

import {EProjectDisplayModes} from "@/components/project/list/models";
import {EModules} from "@/models";
import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import {EAnalysisLayouts} from "@shared/analysis/models";
import {EActivities} from "@toolbox/building-blocks/models";

import {isSpoc} from "@toolbox/functions/engine-check";

interface IEventHandler {
    realtimeChanged?(realtime: IRealTime): void;
}

export interface IAnalysisLayouts {
    frac: EAnalysisLayouts;
    spoc: EAnalysisLayouts;
    cent: EAnalysisLayouts;
}

export interface IRealTime {
    layout: IAnalysisLayouts;
    projectView: EProjectDisplayModes;
    activities: EActivities;
}

export const DEFAULT_REALTIME: IRealTime = {
    layout: {
        cent: EAnalysisLayouts.Container_4_8,
        frac: EAnalysisLayouts.Container_6_6,
        spoc: EAnalysisLayouts.Container_3_9,
    },
    projectView: EProjectDisplayModes.Cards,
    activities: EActivities.Visible,
};

class RealTimeService {
    private readonly events = new EventHandlers<IEventHandler>();

    private _value: IRealTime = DEFAULT_REALTIME;

    public get value() {
        return this._value;
    }

    public async setState(value: Partial<IRealTime>) {
        const goOn = this.silentSetState(value);
        if (!goOn) {
            return;
        }

        try {
            await http
                .post("/api/realtime", {json: this._value})
                .json<IRealTime>();
        } catch {
            // should not show anything to the user.
        }
    }

    // use this with caution, as this will not save changes to server.
    public silentSetState(value: Partial<IRealTime>) {
        const updated = {...this._value, ...value};

        if (isEqual(this._value, updated)) {
            return false;
        }

        this._value = updated;
        this.events.publish((x) => x.realtimeChanged?.(this._value));
        return true; // we need to know, if we can send to server or not in realtime.setState()
    }

    @boundMethod
    public async reset() {
        await this.setState(DEFAULT_REALTIME);
    }

    // just to reset user settings on log-out
    public silentReset() {
        this._value = DEFAULT_REALTIME;
    }

    public getLayout(engine: EModules) {
        const {layout} = this._value;
        if (engine === EModules.Frac) {
            return layout.frac;
        } else if (isSpoc(engine)) {
            return layout.spoc;
        } else {
            return layout.cent;
        }
    }

    public setLayout(engine: EModules, value: EAnalysisLayouts) {
        const {layout} = this._value;
        if (engine === EModules.Frac) {
            this.setState({layout: {...layout, frac: value}});
        } else if (isSpoc(engine)) {
            this.setState({layout: {...layout, spoc: value}});
        } else {
            this.setState({layout: {...layout, cent: value}});
        }
    }

    public async retrieve() {
        try {
            const response = await http
                .get("/api/realtime", {cache: "no-cache"})
                .json<Partial<IRealTime>>();

            this._value = {...DEFAULT_REALTIME, ...response};
        } catch {
            this._value = DEFAULT_REALTIME;
        }
    }

    public subscribe(handler: IEventHandler) {
        return this.events.register(handler);
    }
}

const realtime = new RealTimeService();
export default realtime;
