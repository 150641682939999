import {isEqual} from "lodash";

export function filterObjects<TValue>(
    obj: TValue,
    condition: (a: TValue[keyof TValue]) => boolean,
): Partial<TValue> {
    const keys = Object.keys(obj ?? {}) as (keyof TValue)[];
    const filterdObj = keys
        .filter((key) => condition(obj[key]))
        .map((key) => ({[key]: obj[key]})) as Partial<TValue>[];

    return Object.assign({}, ...filterdObj);
}

export function applyObjects<TValue>(
    obj: TValue,
    condition: (a: TValue[keyof TValue]) => TValue[keyof TValue],
): TValue {
    const keys = Object.keys(obj ?? {}) as (keyof TValue)[];
    const appliedObj = keys.map((key) => ({
        [key]: condition(obj[key]),
    })) as Partial<TValue>[];

    return Object.assign({}, ...appliedObj);
}

export function convertObjects<TValue, T2Value>(
    obj: TValue,
    condition: (a: TValue[keyof TValue]) => T2Value[keyof T2Value],
): T2Value {
    const keys = Object.keys(obj ?? {}) as (keyof TValue)[];
    const convertedObj = keys.map((key) => ({
        [key]: condition(obj[key]),
    })) as Partial<T2Value>[];

    return Object.assign({}, ...convertedObj);
}

export function reMapObjects<TValue extends T2Value, T2Value>(
    obj: TValue,
    dummy: T2Value,
): T2Value {
    const keys = Object.keys(dummy ?? {}) as (keyof T2Value)[];
    const convertedObj = keys.map((key) => ({[key]: obj[key]})) as T2Value[];

    return Object.assign({}, ...convertedObj);
}

export function searchObjectsSome<TValue>(
    obj: TValue,
    condition: (a: TValue[keyof TValue]) => boolean,
    betterKeys?: (keyof TValue)[],
): boolean {
    const keys = betterKeys ?? (Object.keys(obj ?? {}) as (keyof TValue)[]);

    return keys.some((key) => condition(obj[key]));
}

export function searchObjectsEvery<TValue>(
    obj: TValue,
    condition: (a: TValue[keyof TValue]) => boolean,
    betterKeys?: (keyof TValue)[],
): boolean {
    const keys = betterKeys ?? (Object.keys(obj ?? {}) as (keyof TValue)[]);

    return keys.every((key) => condition(obj[key]));
}

export function ultraIsEqual<TValue>(newObj: TValue, oldObj: TValue): boolean {
    const keys = Object.keys(oldObj ?? {}) as (keyof TValue)[];

    return keys.every((key) => isEqual(newObj[key], oldObj[key]));
}

export function sortObject<TValue>(obj: TValue): TValue {
    const keys = Object.keys(obj ?? {}) as (keyof TValue)[];
    keys.sort(); // should be fine without any input

    const sortedObj = keys.map((key) => ({
        [key]: obj[key],
    })) as Partial<TValue>[];

    return Object.assign({}, ...sortedObj);
}

export function convert2Array<TValue>(obj: TValue) {
    const keys = Object.keys(obj ?? {}) as (keyof TValue)[];

    return keys.map((key) => obj[key]);
}
