import "./Functions.module.scss";

import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {extent} from "d3-array";
import React from "react";
import {IntlContext, IntlShape, injectIntl} from "react-intl";

import {ERoles} from "@/services/models";
import session from "@/services/session";
import {DEFAULT_TEMPERATURE_DECIMAL} from "@toolbox/display-blocks/models";
import {EPropertyTypes, IMaterialFunctionModel} from "../models";

import MathBlock, {renderKatexString} from "@/components/math/MathBlock";
import MathEvaluator from "@/components/math/math-evaluator";
import {getDisplayLabel} from "@chart/chart-labels";
import Tooltip from "@toolbox/building-blocks/Tooltip";
import {displayWavelength} from "@toolbox/display-blocks/Wavelength";
import {kev2Nm, nm2Kev} from "@toolbox/functions/units/length";
import T, {intl2Num, intl2Str, parseNumber} from "@translate/T";
import {getTempDefault, getWaveDefault} from "./editor-modal/FunctionTest";

export function usesTemperature(type: EPropertyTypes) {
    switch (type) {
        default:
        case EPropertyTypes.Attenuation:
            return false;

        case EPropertyTypes.Viscosity:
        case EPropertyTypes.Density:
        case EPropertyTypes.Rpri:
        case EPropertyTypes.Ipri:
        case EPropertyTypes.MassCoefficient:
            return true;
    }
}

export function usesWavelength(type: EPropertyTypes) {
    switch (type) {
        default:
        case EPropertyTypes.Viscosity:
        case EPropertyTypes.Density:
            return false;

        case EPropertyTypes.Rpri:
        case EPropertyTypes.Ipri:
        case EPropertyTypes.Attenuation:
        case EPropertyTypes.MassCoefficient:
            return true;
    }
}

export function usesEnergy(type: EPropertyTypes) {
    switch (type) {
        default:
        case EPropertyTypes.Viscosity:
        case EPropertyTypes.Density:
        case EPropertyTypes.Rpri:
        case EPropertyTypes.Ipri:
            return false;

        case EPropertyTypes.Attenuation:
        case EPropertyTypes.MassCoefficient:
            return true;
    }
}

export function getDecimals(type: EPropertyTypes) {
    switch (type) {
        default:
        case EPropertyTypes.Viscosity:
        case EPropertyTypes.Density:
            return 3;

        case EPropertyTypes.Rpri:
        case EPropertyTypes.Ipri:
            return 4; // connected to ComplexInput.tsx default rounding

        case EPropertyTypes.Attenuation:
            return 1;

        case EPropertyTypes.MassCoefficient:
            return 4;
    }
}

export function getEnergyWavelength(
    value: number,
    type: EPropertyTypes,
    transformBack = false,
) {
    if (usesEnergy(type)) {
        return transformBack ? kev2Nm(value) : nm2Kev(value);
    }

    return value;
}

export function getFormulaUnit(type: EPropertyTypes) {
    switch (type) {
        case EPropertyTypes.Viscosity:
            return (
                <React.Fragment>
                    <IntlContext.Consumer
                        children={getDisplayLabel({
                            name: () => "",
                            unit: (intl) => intl2Str(intl, "mPa * s"),
                        })}
                    />
                </React.Fragment>
            );

        case EPropertyTypes.Density:
            return (
                <React.Fragment>
                    <IntlContext.Consumer
                        children={getDisplayLabel({
                            name: () => "",
                            unit: (intl) => intl2Str(intl, "kg/m³"),
                        })}
                    />
                </React.Fragment>
            );

        case EPropertyTypes.Attenuation:
            return (
                <React.Fragment>
                    <IntlContext.Consumer
                        children={getDisplayLabel({
                            name: () => "",
                            unit: (intl) => intl2Str(intl, "1/cm"),
                        })}
                    />
                </React.Fragment>
            );

        case EPropertyTypes.MassCoefficient:
            return (
                <React.Fragment>
                    <IntlContext.Consumer
                        children={getDisplayLabel({
                            name: () => "",
                            unit: (intl) => intl2Str(intl, "cm²/g"),
                        })}
                    />
                </React.Fragment>
            );

        default:
        case EPropertyTypes.Ipri:
        case EPropertyTypes.Rpri:
            return null;
    }
}

export function getTemperatureRange(
    intl: IntlShape,
    value: IMaterialFunctionModel,
    type: EPropertyTypes,
) {
    if (
        usesTemperature(type) &&
        value.minTemperature !== undefined &&
        !isNaN(value.minTemperature) &&
        value.maxTemperature !== undefined &&
        !isNaN(value.maxTemperature)
    ) {
        return (
            <React.Fragment>
                {intl2Num(
                    intl,
                    value.minTemperature,
                    DEFAULT_TEMPERATURE_DECIMAL,
                ) + " "}
                <T>°C</T>
                {" - "}
                {intl2Num(
                    intl,
                    value.maxTemperature,
                    DEFAULT_TEMPERATURE_DECIMAL,
                ) + " "}
                <T>°C</T>
            </React.Fragment>
        );
    }

    return "-";
}

export function getWavelengthRange(
    intl: IntlShape,
    value: IMaterialFunctionModel,
    type: EPropertyTypes,
) {
    if (
        usesWavelength(type) &&
        value.minWavelength !== undefined &&
        !isNaN(value.minWavelength) &&
        value.maxWavelength !== undefined &&
        !isNaN(value.maxWavelength)
    ) {
        const [min, max] = extent([
            getEnergyWavelength(value.minWavelength, type),
            getEnergyWavelength(value.maxWavelength, type),
        ]) as [number, number];

        return (
            displayWavelength(intl, getEnergyWavelength(min, type, true)) +
            " - " +
            displayWavelength(intl, getEnergyWavelength(max, type, true))
        );
    }

    return "-";
}

interface IFunctionRowProps {
    intl: IntlShape;
    value: IMaterialFunctionModel;
    id: string;
    type: EPropertyTypes;
    showResults: boolean;

    onEdit(value: IMaterialFunctionModel): void;
    onDelete(value: IMaterialFunctionModel): void;
}

class FunctionRow extends React.PureComponent<IFunctionRowProps> {
    private readonly evaluator: MathEvaluator = new MathEvaluator(
        usesTemperature(this.props.type),
        usesWavelength(this.props.type),
        usesEnergy(this.props.type),
    );

    @boundMethod
    public delete(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onDelete, value} = this.props;
        onDelete(value);
    }

    @boundMethod
    public onFunctionClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onEdit, value} = this.props;
        onEdit(value);
    }

    public render() {
        const {intl, value, type} = this.props;
        const useTemperature = usesTemperature(type);
        const useWavelength = usesWavelength(type);

        return (
            <tr>
                <td>
                    {value.formula ? (
                        <MathBlock
                            formula={value.formula}
                            useEnergy={usesEnergy(type)}
                        />
                    ) : (
                        "-"
                    )}
                </td>
                {this.renderResult()}
                {useTemperature && (
                    <td className="space-nowrap" styleName="single-cell">
                        {getTemperatureRange(intl, value, type)}
                    </td>
                )}
                {useWavelength && (
                    <td className="space-nowrap" styleName="single-cell">
                        {getWavelengthRange(intl, value, type)}
                    </td>
                )}
                <td styleName="comments-cell">{value.notes}</td>
                {this.renderButtons()}
            </tr>
        );
    }

    private renderResult() {
        const {intl, value, type, showResults} = this.props;
        const useTemperature = usesTemperature(type);
        const useWavelength = usesWavelength(type);
        const useEnergy = usesEnergy(type);

        // only show this part, when there is a function in all rows, that has inputs
        if (!showResults) {
            return null;
        }

        // show a table cell, but no calcs
        if (!isNaN(parseNumber(value.formula))) {
            return <td>-</td>;
        }

        // first calc the result
        const temperature = getTempDefault(
            value.minTemperature ?? 0,
            value.maxTemperature ?? 0,
        );
        const wavelength = getWaveDefault(
            type,
            ...(extent([
                value.minWavelength ?? NaN,
                value.maxWavelength ?? NaN,
            ]) as [number, number]),
        );
        const result = this.evaluator.getOnlyResultLatex(
            intl,
            value.formula,
            {temperature, wavelength: getEnergyWavelength(wavelength, type)},
            getDecimals(type),
        );

        // add tooltip for user, so they know the used numbers
        let tooltip = intl2Str(intl, "Function test from default values:");
        if (useTemperature) {
            tooltip +=
                " " +
                intl2Num(intl, temperature, DEFAULT_TEMPERATURE_DECIMAL) +
                intl2Str(intl, "°C");
        }

        if (useWavelength || useEnergy) {
            tooltip += " " + displayWavelength(intl, wavelength);
        }

        return (
            <td className="space-nowrap" styleName="single-cell">
                <Tooltip content={tooltip}>{renderKatexString(result)}</Tooltip>
            </td>
        );
    }

    private renderButtons() {
        const {id} = this.props;
        if (!session.hasRole(ERoles.Editor)) {
            return null;
        }

        return (
            <td className="space-nowrap" styleName="button-cell">
                <div>
                    <button
                        type="button"
                        id={id}
                        className="btn btn-secondary w-100"
                        onClick={this.onFunctionClick}
                    >
                        <FontAwesomeIcon
                            icon={faEdit}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Edit</T>
                    </button>
                </div>

                <div className="mt-1">
                    <button
                        type="button"
                        className="btn btn-danger w-100"
                        onClick={this.delete}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Delete</T>
                    </button>
                </div>
            </td>
        );
    }
}

export default injectIntl(FunctionRow);
