import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlContext, IntlShape} from "react-intl";
import {Titled} from "react-titled";

import networkDevices, {
    IDeviceStatus,
    INetworkDeviceConfig,
} from "@/services/network-devices";
import {ILocalizedText} from "@translate/models";

import Card from "@toolbox/design/Card";
import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import T, {intl2Str} from "@translate/T";
import Device from "./Device";

interface IDevicesProps {
    intl: IntlShape;
}

interface IDevicesState {
    config: INetworkDeviceConfig;
    devices: IDeviceStatus[];
}

class Devices extends React.PureComponent<IDevicesProps, IDevicesState> {
    public readonly state: IDevicesState = {
        config: networkDevices.config,
        devices: networkDevices.devices,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = networkDevices.subscribe({
            devicesChanged: (devices) => this.setState({devices}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public getTitle(parent: string) {
        return intl2Str(this.props.intl, "Device connection") + " | " + parent;
    }

    public render() {
        const {ipAddresses, port} = this.state.config;

        const text: ILocalizedText = (intl) =>
            intl2Str(intl, "Port: {port}", {port});

        return (
            <Titled title={this.getTitle}>
                <ContainerCol col={8}>
                    <Card
                        title={
                            <h3>
                                <T>Network device information</T>
                            </h3>
                        }
                    >
                        <div className="card-body">
                            <div className="text-center">
                                <span>
                                    {this.getDeviceTitle(ipAddresses.length)}
                                </span>
                                {ipAddresses.map((ip, idx) => (
                                    <h3 key={idx}>{ip}</h3>
                                ))}
                                <h3>
                                    <IntlContext.Consumer children={text} />
                                </h3>
                            </div>
                        </div>
                    </Card>
                    <h3>
                        <T>Network devices of the last 7 days</T>
                    </h3>
                    <small>
                        <T>
                            (devices with currently established connection in
                            green)
                        </T>
                    </small>
                    <hr />
                    {this.renderNetwork()}
                </ContainerCol>
            </Titled>
        );
    }

    private renderNetwork() {
        const {devices} = this.state;

        if (!devices.length) {
            return (
                <div className="text-warning text-center">
                    <T>No active device within the last 7 days</T>
                </div>
            );
        }

        return <div className="form-row">{this.renderDevices(devices)}</div>;
    }

    private renderDevices(devices: IDeviceStatus[]) {
        return devices.map((x, idx) => (
            <div key={idx} className="col-md-3">
                <Device device={x} />
            </div>
        ));
    }

    private getDeviceTitle(len: number) {
        switch (len) {
            case 0:
                return <T>-</T>;

            case 1:
                return <T>Set your LUM device's SEPView server address to:</T>;

            default:
                return (
                    <T>
                        Set your LUM device's SEPView server address to one of
                        the following:
                    </T>
                );
        }
    }
}

export default injectIntl(Devices);
