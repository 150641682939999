import React from "react";

import {ESopLicenses} from "@/components/license/models";
import {EServerId} from "@shared/models";
import {EDeviceClasses, ILicensedDevice} from "@toolbox/models";
import {ILocalizedText} from "@translate/models";

import {
    deviceName2Device,
    hasSingleChannel,
} from "@toolbox/functions/device-check";
import T, {intl2Str} from "@translate/T";
import LicenseFeatures from "./LicenseFeatures";

export function sopLicenseDisplayName(license: ESopLicenses): ILocalizedText {
    switch (license) {
        case ESopLicenses.Fast:
            return (intl) => intl2Str(intl, "Interval below 5 seconds");

        case ESopLicenses.Long:
            return (intl) => intl2Str(intl, "Duration above 42 hours");

        case ESopLicenses.Magnet:
            return (intl) => intl2Str(intl, "Magnet cell holders");

        case ESopLicenses.Template:
            return (intl) => intl2Str(intl, "Analysis template");

        case ESopLicenses.Unlimited:
            return (intl) =>
                intl2Str(
                    intl,
                    "Unlimited maximum interval, number of profiles and duration",
                );

        case ESopLicenses.TemperatureRamp:
            return (intl) => intl2Str(intl, "Temperature ramp");

        case ESopLicenses.SectorShapedCells:
            return (intl) => intl2Str(intl, "Sector Shaped Cell Types (SSC)");

        case ESopLicenses.ACsvExport:
            return (intl) => intl2Str(intl, "Automatic CSV export (A-CSV)");

        case ESopLicenses.FracUnlimitedDuration:
            return (intl) => intl2Str(intl, "Unlimited SOP duration");

        case ESopLicenses.FracMultiCycleStressCurveSOP:
            return (intl) => intl2Str(intl, "Multi cycle stress curve");
    }
}

function getLicensedSops(
    serverId: EServerId,
    _: ESopLicenses[],
    devices: ILicensedDevice[],
): ESopLicenses[] {
    const sops: ESopLicenses[] = [];
    const deviceClasses: EDeviceClasses[] = [
        ...new Set(devices.map((x) => deviceName2Device(x.deviceClass))),
    ];

    switch (serverId) {
        case EServerId.Centrifuge:
            if (deviceClasses.some((x) => !hasSingleChannel(x))) {
                sops.push(ESopLicenses.Fast, ESopLicenses.Long);
            }

            sops.push(
                ESopLicenses.ACsvExport,
                ESopLicenses.Magnet,
                ESopLicenses.SectorShapedCells,
                ESopLicenses.TemperatureRamp,
                ESopLicenses.Template,
                ESopLicenses.Unlimited,
            );
            break;

        case EServerId.Fracture:
            sops.push(
                ESopLicenses.FracMultiCycleStressCurveSOP,
                ESopLicenses.FracUnlimitedDuration,
            );
            break;

        case EServerId.Spoc:
            sops.push(); // nothing yet
            break;
    }

    return sops;
}

function isNonBuyable(item: ESopLicenses) {
    return [ESopLicenses.Unlimited].includes(item);
}

interface ISopFeaturesProps {
    values: ESopLicenses[];
    devices: ILicensedDevice[];
}

class SopFeatures extends React.PureComponent<ISopFeaturesProps> {
    public render() {
        const {values, devices} = this.props;

        return (
            <LicenseFeatures
                id="licensed-sop-features"
                title={<T>SOP features</T>}
                values={values}
                devices={devices}
                missing="missingSopLicenses"
                getLicensed={getLicensedSops}
                getDisplayName={sopLicenseDisplayName}
                isNonBuyable={isNonBuyable}
            />
        );
    }
}

export default SopFeatures;
