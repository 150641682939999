import "katex/dist/katex.css";
import "./MathBlock.module.scss";

import katex from "katex";
import {MathNode, parse} from "mathjs";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import {KATEX_OPTIONS, TIMES_LATEX} from "./models";

import T, {getDecimal} from "@translate/T";

export function renderKatexString(result: string) {
    const html = katex.renderToString(result, KATEX_OPTIONS);
    return (
        <span
            id="latex-string"
            styleName="even-font-size-for-latex" // font size, that we use everywhere (i hope)
            dangerouslySetInnerHTML={{__html: html}}
        />
    );
}

export function toTex(intl: IntlShape, root: MathNode, doE: boolean = false) {
    return root
        .toTex({
            handler: (node: MathNode) => {
                if (node.type === "SymbolNode") {
                    switch (node.name) {
                        case "T":
                            return "\\theta";

                        case "L":
                            if (doE) {
                                return "E";
                            }

                            return "\\lambda";
                    }
                }

                return node.value;
            },
            parenthesis: "auto",
        })
        .toString()
        .replace(/\./g, getDecimal(intl))
        .replace(/\\cdot/g, TIMES_LATEX);
}

interface IMathBlockProps {
    intl: IntlShape;
    formula?: string; // the latex string to display as formula
    latex?: string;

    useEnergy?: boolean;
}

interface IMathBlockState {
    isValid: boolean;
}

class MathBlock extends React.PureComponent<IMathBlockProps, IMathBlockState> {
    public readonly state: IMathBlockState = {isValid: true};

    private readonly span = React.createRef<HTMLSpanElement>();

    public componentDidMount() {
        this.update();
    }

    public componentDidUpdate() {
        this.update();
    }

    public render() {
        return (
            <React.Fragment>
                <span
                    ref={this.span}
                    id="latex-block"
                    styleName="even-font-size-for-latex" // font size, that we use everywhere (i hope)
                />
                {this.renderError()}
            </React.Fragment>
        );
    }

    private renderError() {
        if (this.state.isValid) {
            return null;
        }

        return (
            <em id="text-danger" className="text-danger">
                <T>Function is invalid!</T>
            </em>
        );
    }

    private update() {
        const {intl, formula, useEnergy, latex} = this.props;
        const span = this.span.current;
        if (!span) {
            return;
        }

        try {
            let _latex = latex;
            if (formula !== undefined) {
                const node = parse(formula);
                _latex = toTex(intl, node, useEnergy);
            }

            if (!_latex) {
                this.setState({isValid: false});
                return;
            }

            katex.render(_latex, span, KATEX_OPTIONS);
            this.setState({isValid: true});
        } catch {
            this.setState({isValid: false});
        }
    }
}

export default injectIntl(MathBlock);
