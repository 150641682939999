import "./AnalyzeModal.module.scss";

import {boundMethod} from "autobind-decorator";
import {debounce} from "lodash";
import React from "react";
import {OutPortal, createHtmlPortalNode} from "react-reverse-portal";

import {ESopLicenses} from "@/components/license/models";
import {EModules} from "@/models";
import license from "@/services/license";
import {CLOSING_DELAY} from "@toolbox/models";
import {EServerId} from "../../models";
import {IModuleGroup} from "../analyze-button/models";
import {EAnalysisTab} from "./models";

import PagerPageSizeFooter from "@toolbox/building-blocks/PagerPageSizeFooter";
import Modal from "@toolbox/modals/Modal";
import T, {parseNumber} from "@translate/T";
import AnalyzeStandard from "./AnalyzeStandard";
import AnalyzeTemplate from "./AnalyzeTemplate";

interface IAnalyzeModalProps {
    project: number;
    serverId: EServerId;
    modules: IModuleGroup[];

    hrefTarget?: string;

    getTargets(engine?: EModules): string;
    afterClose(): void;
}

interface IAnalyzeModalState {
    tab: EAnalysisTab;
}

class AnalyzeModal extends React.PureComponent<
    IAnalyzeModalProps,
    IAnalyzeModalState
> {
    public readonly state: IAnalyzeModalState = {tab: EAnalysisTab.Standard};

    private readonly portal = createHtmlPortalNode<PagerPageSizeFooter>();
    private readonly ref = React.createRef<Modal>();
    private readonly close = debounce(
        () => this.ref.current?.triggerClose(),
        CLOSING_DELAY,
    );

    private get hasTemplates() {
        return (
            license.status.sops.includes(ESopLicenses.Template) &&
            this.props.serverId !== EServerId.Spoc
        );
    }

    public componentWillUnmount() {
        this.resetTimer();
    }

    @boundMethod
    public onClick(e: React.SyntheticEvent<HTMLAnchorElement>) {
        e.preventDefault();

        const id = e.currentTarget.dataset.tab as string;
        this.setState({tab: parseNumber(id)});
    }

    @boundMethod
    public startTimer() {
        window.addEventListener("focus", this.resetTimer, {passive: true});
        this.close();
    }

    @boundMethod
    public resetTimer() {
        window.removeEventListener("focus", this.resetTimer);
        this.close.cancel();
    }

    public render() {
        const {modules, afterClose} = this.props;
        const {tab} = this.state;
        const len = Math.max(...modules.map((x) => x.modules.length));

        return (
            <Modal
                ref={this.ref}
                header={<T>Create new analysis</T>}
                size={this.getModalSize(len)}
                afterClose={afterClose}
            >
                <div className="modal-body">
                    {this.renderTabs()}
                    <div styleName="analyze-tab">{this.renderContent(len)}</div>
                </div>

                {tab === EAnalysisTab.Template && (
                    <OutPortal node={this.portal} />
                )}
            </Modal>
        );
    }

    private renderTabs() {
        if (!this.hasTemplates) {
            return null;
        }

        return (
            <ul className="nav nav-tabs mb-2" role="tablist">
                <li className="nav-item tab">
                    <a
                        id={"analyzed-tab-" + EAnalysisTab.Standard}
                        className="nav-link active"
                        aria-controls="standard"
                        aria-selected="true"
                        data-tab={EAnalysisTab.Standard}
                        data-toggle="tab"
                        href="#standard"
                        role="tab"
                        onClick={this.onClick}
                    >
                        <span className="nav-span">
                            <T>Standard analysis</T>
                        </span>
                    </a>
                </li>
                <li className="nav-item tab">
                    <a
                        id={"analyzed-tab-" + EAnalysisTab.Template}
                        className="nav-link"
                        aria-controls="template"
                        aria-selected="false"
                        data-tab={EAnalysisTab.Template}
                        data-toggle="tab"
                        href="#template"
                        role="tab"
                        onClick={this.onClick}
                    >
                        <span className="nav-span">
                            <T>Analysis template</T>
                        </span>
                    </a>
                </li>
            </ul>
        );
    }

    private renderContent(columns: number) {
        const {modules, hrefTarget, project, getTargets, serverId} = this.props;

        return (
            <div className="tab-content">
                <div
                    id="standard"
                    role="tabpanel"
                    className="tab-pane fade show active"
                >
                    <AnalyzeStandard
                        hrefTarget={hrefTarget}
                        columns={columns}
                        modules={modules}
                        project={project}
                        serverId={serverId}
                        getTargets={getTargets}
                        onClick={this.startTimer}
                    />
                </div>

                <div id="template" className="tab-pane fade" role="tabpanel">
                    <AnalyzeTemplate
                        hrefTarget={hrefTarget}
                        columns={columns}
                        modules={modules}
                        project={project}
                        serverId={serverId}
                        targets={getTargets()}
                        portal={this.portal}
                        onClick={this.startTimer}
                    />
                </div>
            </div>
        );
    }

    private getModalSize(len: number) {
        switch (len) {
            case 1:
                return "sm";

            case 2:
                return "md";

            case 3:
                return "lg";

            case 4:
            default:
                return "xl";
        }
    }
}

export default AnalyzeModal;
